import * as yup from "yup"

export const addProductValidationSchema = yup.object({
  // name: yup.string('Enter name').required('Name is required'),
  description: yup.string('Enter description').required('Description is required'),
  year: yup.string('Enter year').required('Year is required'),
  hours: yup.string('Enter hours').required('Hours is required'),
  model: yup.string('Enter model').required('Model is required'),
  serial: yup.string('Enter serial').required('Serial is required'),
  price: yup.string('Enter price').required('Price is required'),
  location: yup.string('Enter location').required('Location is required'),
  brand: yup.string('Enter brand ').required('Brand is required'),
  category: yup.string('Enter category').required('Category is required'),
  // discountPercentage: yup.string('Enter Discount Percentage').required('Discount Percentage is required'),
});

export const addProductInitialValues = {
  // name: "",
  year: "",
  hours: "",
  model: "",
  serial: "",
  price: "",
  location: "",
  description: "",
  category: "",
  brand: "",
  images: null,
}