import axios from "axios";
import { useFormik } from "formik";
import { Col, Row, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
    Anchor,
    Box,
    Button,
    Icon,
    Image,
    Input,
    Label,
    Text,
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { addBrandInitialValues, addBrandValidationSchema } from "../../schemas/AddBrand";
import { useEffect, useState } from "react";
import { CategoryRounded, TitleRounded } from "@mui/icons-material";
import data from "../../data/master/productUpload.json";
import { useSelector } from "react-redux";

export default function BrandUpload() {
  const navigate = useNavigate();
  const {token} = useSelector((state)=>state.auth);
  const [bool , setBool]=useState(false)
  const [isLoading, setIsLoading] = useState(false);

  const addBrandFormik = useFormik({
    initialValues: addBrandInitialValues,
    validationSchema: addBrandValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if(!values.images){
        handleAlert("Please Choose Brand Images","erorr")
        return
      }
      console.log("inside form");
      const formData = new FormData();
      
      new Array(values.images.length).fill(0).map((_,i) => {
        formData.append(`image`, values.images[i]);
        return 0;
      });
      
      formData.append('name', values.name);
      
      // console.log("done one");
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/brand`, formData,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        })
        .then((res) => {
          // console.log("done two");
          navigate(`${process.env.REACT_APP_BRANDS_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("no");
          setIsLoading(true);
          handleAlert(err.response.data.message, "error");
        });

      // await axios
      //   .post(`${process.env.REACT_APP_SERVER_URL}/brand`, values)
      //   .then((res) => {
      //     console.log(res.data);
      //     navigate(`${process.env.REACT_APP_COUPONS_LIST_ROUTE}`);
      //     handleAlert(res.data.message, "success");
      //     resetForm();
      //   })
      //   .catch((err) => {
      //     handleAlert(err.response.data.message, "error");
      //   });
    },
  });

  // useEffect(()=>{
  //   setBool(!bool)
  //   addBrandFormik.values.expirationDate = new Date().toISOString().split('T')[0]
  // },[])

  const handleSub = (e)=>{
    e.preventDefault()
    console.log("hello");
  }

  return (
    <PageLayout>
      {/* <form onSubmit={handleSub}> */}
      <form onSubmit={addBrandFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Add Brand"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  Brand
                </li>
                <li className="mc-breadcrumb-item">
                  Upload
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="Brand information" />
              <Row>
                <Col xl={12}>
                  <MuiField
                    name="name"
                    label="Brand Name"
                    type="text"
                    icon={<CategoryRounded />}
                    formik={addBrandFormik}
                  />
                </Col>
              </Row>
            </CardLayout>
          </Col>
          <Col xl={6}>
            <CardLayout>
              <CardHeader
                title="Brand Images"
              />
              <Box className="mc-product-upload-media">
                <Box className="mc-product-upload-file">
                  <Input
                    type="file"
                    id="images"
                    multiple
                    onChange={(event) => {
                      addBrandFormik.setFieldValue('images', event.currentTarget.files);
                    }}
                  />
                  <Label htmlFor="images">
                    <Icon type="collections" />
                    <Text className={`${addBrandFormik.values.images && "whatsapp"}`}>{addBrandFormik.values.images ? "Upload Successfully!!" : "Upload Images"}</Text>
                  </Label>
                </Box>
              </Box>
            </CardLayout>
          </Col>
          <Col xl={6}>
            {addBrandFormik.values.images && (
              <CardLayout>
                <CardHeader
                  title="Chosen Images"
                />
                <Box className={`grid jcs aifs g10 images_box_2`}>
                  {
                    new Array(addBrandFormik.values.images.length).fill(0).map((_, i) => {
                      return (
                        <Image key={i} src={URL.createObjectURL(addBrandFormik.values.images[i])} alt={"images"} className={`image`} />
                      )
                    })
                  }
                </Box>
              </CardLayout>
            )}
          </Col>
          <Col xl={12}>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={'submit'}
            >
              {isLoading ? <Spinner animation="grow" /> : "Add Brand"}
            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
