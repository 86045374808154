import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getBrandsList = createAsyncThunk("brandsList/getBrandsList", async (args) => {
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
  let res;
  if (args) {
    const { search, expired, category, brand } = args
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/brand?limit=30&page=0&search=${search || ""}&category=${category || ""}&brand=${brand || ""}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  } else {
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/brand?limit=30&page=0&search=`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
  // console.log(res.data);
  return res.data
})

const initialState = {
  brandsList: null,
  isLoading: true
}

export const brandsListSlice = createSlice({
  name: 'brandsList',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getBrandsList.pending, (state, { payload }) => {
      state.isLoading = true
    })
    builder.addCase(getBrandsList.fulfilled, (state, { payload }) => {
      state.brandsList = payload
      state.isLoading = false
    })
    builder.addCase(getBrandsList.rejected, (_, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage, "error")
      } else {
        handleAlert(action.error.message, "error")
      }
    })
  }
})


export default brandsListSlice.reducer