import { createContext, useState } from "react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userId , setUserId] = useState(null)
    const [editableUserData, setEditableUserData] = useState(null)

    return (
        <UserContext.Provider value={{editableUserData, setEditableUserData,userId,setUserId}}>
            { children }
        </UserContext.Provider>
    )
}