import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getBrand = createAsyncThunk("brand",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/brand/${args.id}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  // console.log(res.data);
  return res
})

const initialState = {
  brand:null,
  isLoading:true
}

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getBrand.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getBrand.fulfilled, (state, { payload }) => {
      state.brand = payload.data
      state.isLoading = false
    })
    builder.addCase(getBrand.rejected, (state, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})


export default brandSlice.reducer