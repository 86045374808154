import { CategoryRounded, RateReviewRounded, SearchRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Pagination } from "../../components";
import { CardHeader, CardLayout, FloatCard } from "../../components/cards";
import Anchor from "../../components/elements/Anchor";
import { MuiField } from "../../components/elements/MuiField";
import ProductsTable from "../../components/tables/ProductsTable";
import data from "../../data/master/productList.json";
import PageLayout from "../../layouts/PageLayout";
import { getCategory } from "../../store/categoriesSlice";
import { getProductsCounter } from "../../store/productsCounterSlice";
import { getProductsList } from "../../store/productsListSlice";
import axios from "axios";

export default function ProductList() {
  const [category, setCategory] = useState("")
  const [rating, setRating] = useState("")
  const [search, setSearch] = useState("")
  const { productsList } = useSelector((state) => state.productsList)
  const { categories } = useSelector((state) => state.category);
  const { token } = useSelector((state) => state.auth);
  const { productsCounter, categoriesCounter, usersCounter, soldCounter, brandsCounter } = useSelector((state) => state.productsCounter)
  const dispatch = useDispatch()
  const [categoryy, setCategoryy] = useState([]);
  const [brand, setBrand] = useState([]);

  const handleChangeCategory = async (e) => {
    let cat = e.target.value;
    console.log(cat);
    setCategory(cat)
    dispatch(getProductsList({ category: cat, rating, search }))
  }

  const handleChangeRating = async (e) => {
    let rate = e.target.value;
    setRating(rate)
    dispatch(getProductsList({ category, rating: rate, search }))
  }

  const handleChangeSearch = async (e) => {
    let s = e.target.value;
    setSearch(s)
    dispatch(getProductsList({ category, rating, search: s }))
  }

  const getAllCatandBrand = async () => {
    let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/categories-brands`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setCategoryy(res.data.categories.map((cat) => {
      return cat.name
    }));
    setBrand(res.data.brands
      .map((brand) => {
      return brand.name
    }))
  }


  useEffect(() => {
    getAllCatandBrand();
    dispatch(getProductsList())
    dispatch(getProductsCounter())
    // dispatch(getCategory())
  }, [dispatch])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Products List"}>
              <li className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </li>
              <li className="mc-breadcrumb-item">
                Product
              </li>
              <li className="mc-breadcrumb-item">
                List
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg blue"}
            digit={productsCounter}
            title={"total products"}
            icon={"shopping_bag"}
          />
        </Col>
        <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg green"}
            digit={categoriesCounter}
            title={"total categories"}
            icon={"widgets"}
          />
        </Col>
        <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg yellow"}
            digit={brandsCounter}
            title={"total brands"}
            icon={"widgets"}
          />
        </Col>
        <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg red"}
            digit={soldCounter}
            title={"Total Sold Product"}
            icon={"widgets"}
          />
        </Col>
        <Col xl={12}>
          <CardLayout>
            <CardHeader title={"Products"} />
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <MuiField
                  placeholder="Category By"
                  select={true}
                  value={category}
                  icon={<CategoryRounded />}
                  onChange={handleChangeCategory}
                  // data={categories ? Object.keys(categories) : []}
                  data={categoryy}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <MuiField
                  placeholder="Brand By"
                  select={true}
                  value={rating}
                  icon={<RateReviewRounded />}
                  onChange={handleChangeRating}
                  data={brand}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <MuiField
                  placeholder="Search..."
                  type={"search"}
                  value={search}
                  icon={<SearchRounded />}
                  onChange={handleChangeSearch}
                />
              </Col>
              <Col xl={12}>
                <ProductsTable
                  thead={data?.product.thead}
                  tbody={productsList}
                />
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

    </PageLayout>
  );
}
