import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getUsersCounter=createAsyncThunk("usersCounter/getUsersCounter",async()=>{
  // const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  // const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}dashboard/userCounters`,{
  //   headers:{
  //     Authorization:`Bearer ${token}`
  //   }
  // })
  // console.log(res.data);
  // return res.data.data
})

const initialState = {
  usersCounter:0,
  userTypes:null,
  isLoading:true
}

export const usersCounterSlice = createSlice({
  name: 'usersCounter',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getUsersCounter.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUsersCounter.fulfilled, (state, { payload }) => {
      let total = 0
      // payload.map((pay)=>{
      //   total += pay.count
      //   return  0;
      // })
      state.userTypes = payload
      state.usersCounter = total
      state.isLoading = false
    })
    builder.addCase(getUsersCounter.rejected, (state, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})

export default usersCounterSlice.reducer