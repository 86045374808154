import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getProductsCounter = createAsyncThunk("productsCounter/getProductsCounter", async () => {
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
 
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/allCounters`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return res.data
})

const initialState = {
  productsCounter: 0,
  categoriesCounter: 0,
  brandsCounter: 0,
  usersCounter: 0,
  totalSales: 0,
  totalOffers: 0,
  soldCounter: 0,
  isLoading: true
}

export const productsCounterSlice = createSlice({
  name: 'productsCounter',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsCounter.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProductsCounter.fulfilled, (state, { payload }) => {
      state.productsCounter = payload.totalProducts
      state.categoriesCounter = payload.totalCategories
      state.brandsCounter = payload.totalBrands
      state.usersCounter = payload.totalUsers
      state.totalSales = payload.totalSales
      state.totalOffers = payload.totalOffers
      state.soldCounter = payload.totalSoldProducts
      state.isLoading = false
    })
    builder.addCase(getProductsCounter.rejected, (_, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage, "error")
      } else {
        handleAlert(action.error.message, "error")
      }
    })
  }
})

export default productsCounterSlice.reducer