import { CategoryRounded, RateReviewRounded, SearchRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Pagination } from "../../components";
import { CardHeader, CardLayout, FloatCard } from "../../components/cards";
import Anchor from "../../components/elements/Anchor";
import { MuiField } from "../../components/elements/MuiField";
import ProductsTable from "../../components/tables/ProductsTable";
import data from "../../data/master/productList.json";
import PageLayout from "../../layouts/PageLayout";
import { getCategory } from "../../store/categoriesSlice";
import { getProductsCounter } from "../../store/productsCounterSlice";
import { getProductsList } from "../../store/productsListSlice";
import BrandTable from "../../components/tables/BrandTable";
import { getBrandsList } from "../../store/brandsListSlice";

export default function BrandList() {
  const [category , setCategory] = useState("")
  const [brand , setBrand] = useState("")
  const [rating , setRating] = useState("")
  const [search , setSearch] = useState("")
  const {productsList} = useSelector((state)=>state.productsList);
  const {categories} = useSelector((state)=>state.category);
  const {brandsList} = useSelector((state)=>state.brandsList);
  // const {brands} = useSelector((state)=>state.brandsList)
  const {productsCounter ,categoriesCounter ,brandsCounter} = useSelector((state)=>state.productsCounter)
  const dispatch = useDispatch();

  const handleChangeCategory = async(e)=>{
    // let cat=  e.target.value;
    // setCategory(cat);
    // setBrand(cat);
    // dispatch(getProductsList({brand:cat,rating,search}))
    // dispatch(getBrandsList());
    
  }
  
  const handleChangeRating = async(e)=>{
    // let rate=  e.target.value;
    // setRating(rate)
    // dispatch(getProductsList({category,rating:rate,search}))
    // getBrandsList();
  }
  
  const handleChangeSearch = async(e)=>{
    let s=  e.target.value;
    setSearch(s)
    dispatch(getBrandsList({category,rating,search:s}))
    getBrandsList()
  }


  useEffect(()=>{
    dispatch(getBrandsList())
    dispatch(getProductsCounter())
    // dispatch(getCategory())
  },[dispatch])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Brand List"}>
              <li className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </li>
              <li className="mc-breadcrumb-item">
                Brand
              </li>
              <li className="mc-breadcrumb-item">
                List
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg blue"}
            digit={brandsCounter}
            title={"total brand"}
            icon={"shopping_bag"}
          />
        </Col>
        <Col xl={12}>
          <CardLayout>
            <CardHeader title={"Brands"} />
            <Row>
              {/* <Col xs={12} sm={6} md={4} lg={4}>
                <MuiField
                  placeholder="Brand By"
                  select={true}
                  value={rating}
                  icon={<RateReviewRounded/>}
                  onChange = {handleChangeRating}
                  data={["CAT","CATERPILLAR" , "VOLVO" , "CAT" , "DYNAPAC ","CAT"]}
                />
              </Col> */}
              <Col xs={12} sm={6} md={4} lg={4}>
                <MuiField
                  placeholder="Search..."
                  type={"search"}
                  value={search}
                  icon={<SearchRounded/>}
                  onChange = {handleChangeSearch}
                />
              </Col>
              
              <Col xl={12}>
                <BrandTable
                  thead={data?.product.thead}
                  tbody={brandsList}
                />
                {brandsList && brandsList.length > 10 && <Pagination />}
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

    </PageLayout>
  );
}
