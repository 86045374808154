import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Item, Anchor } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import InvoiceTable from "../../components/tables/FormsTable";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/invoiceList.json";
import FormsTable from "../../components/tables/FormsTable";
import { useDispatch, useSelector } from "react-redux";
import { getFormsList } from "../../store/formsListSlice";
import { getOrdersList } from "../../store/ordersListSlice";
import { getOrderCounter } from "../../store/orderCounterSlice";

export default function FormsList() {
  const { formsList } = useSelector((state) => state.formsList);
  const { orderCounter, isLoading } = useSelector((state) => state.orderCounter)

  const dispatch = useDispatch();
  // console.log(orderCounter.offer);


  useEffect(() => {
    dispatch(getFormsList());
    dispatch(getOrdersList());
    dispatch(getOrderCounter());


  }, [dispatch])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </CardLayout>
        </Col>
        {/* {data?.float.map((item, index) => (
          <Col key={index} md={6} lg={4}>
            <FloatCard
              variant={item.variant}
              digit={item.digit}
              title={item.title}
              icon={item.icon}
            />
          </Col>
        ))} */}
        <Col md={6} lg={3}>
          <FloatCard
            variant="lg green"
            digit={orderCounter && orderCounter.offer}
            title="Offers"
            icon="pending"
          />
        </Col>
        <Col md={6} lg={3}>
          <FloatCard
            variant="lg purple"
            digit={orderCounter && orderCounter.contact_us}
            title="Contact Us"
            icon="shopping_bag"
          />
        </Col>
        <Col md={6} lg={3}>
          <FloatCard
            variant="lg yellow"
            digit={orderCounter && orderCounter.subscription}
            title="Subscription"
            icon="account_balance"
          />
        </Col>
        <Col md={6} lg={3}>
          <FloatCard
            variant="lg blue"
            digit={orderCounter && orderCounter.suggestion}
            title="Suggestion"
            icon="drafts"
          />
        </Col>
        <Col xl={12}>
          <CardLayout>
            <CardHeader title="Forms List" dotsMenu={data?.dotsMenu} />
            {/* <Row xs={1} sm={2} lg={4} className="mb-4">
              {data?.filter.map((item, index) => (
                <Col key={index}>
                  <LabelField
                    type={item.type}
                    label={item.label}
                    option={item.option}
                    placeholder={item.placeholder}
                    labelDir="label-col"
                    fieldSize="w-100 h-md"
                  />
                </Col>
              ))}
            </Row> */}
            <FormsTable
              thead={data?.table.thead}
              tbody={formsList}
            />
            {/* <Pagination /> */}
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
