import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getOrder = createAsyncThunk("order", async (args) => {
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/order/${args.id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  // console.log(res.data);
  return res
})

const initialState = {
  order: null,
  reviews: null,
  isLoading: true
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getOrder.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.order = payload.data
      // state.reviews = payload.reviews.reverse()
      state.isLoading = false
    })
    builder.addCase(getOrder.rejected, (state, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage, "error")
      } else {
        handleAlert(action.error.message, "error")
      }
    })
  }
})

export default orderSlice.reducer