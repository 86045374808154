import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import App from "./App";
import {
  Analytics,
  CRM,
  Ecommerce,
  ForgotPassword,
  InvoiceDetails,
  InvoiceList,
  Login,
  Message,
  MyAccount,
  Notification,
  OrderList,
  Pages,
  ProductList,
  ProductUpload,
  ProductView,
  Register,
  Settings,
  UserList,
  UserProfile,
} from "./pages/master";
import BlogEdit from "./pages/master/BlogEdit";
import BlogList from "./pages/master/BlogList";
import BlogUpload from "./pages/master/BlogUpload";
import CategoryUpload from "./pages/master/CategoryUpload";
import CouponEdit from "./pages/master/BrandEdit";
import BrandUpload from "./pages/master/BrandUpload";
import CouponsList from "./pages/master/CouponsList";
import ProductEdit from "./pages/master/ProductEdit";
import ResetPassword from "./pages/master/ResetPassword";
import { Error } from "./pages/supports";
import MailList from "./pages/master/MailList";
import CreateUser from "./pages/master/CreateUser";
import BrandList from "./pages/master/BrandList";
import CategoryList from "./pages/master/CategoryList";
import BrandEdit from "./pages/master/BrandEdit";
import CategoryEdit from "./pages/master/CategoryEdit";
import OrderView from "./pages/master/OrderView";
import FormsList from "./pages/master/formsList";

const TheRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />}>
          {/* master Pages */}
          <Route index element={<Ecommerce />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="crm" element={<CRM />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:id" element={<ResetPassword />} />
          <Route path="user-list" element={<UserList />} />
          <Route path="create-user" element={<CreateUser />} />
          <Route path="user-profile" element={<UserProfile />} />
          <Route path="my-account" element={<MyAccount />} />
          <Route path="product-list" element={<ProductList />} />
          <Route path="product-view" element={<ProductView />} />
          <Route path="product-upload" element={<ProductUpload />} />
          <Route path="product-edit" element={<ProductEdit />} />
          {/* <Route path="coupon-list" element={<CouponsList />} /> */}
          <Route path="brand-list" element={<BrandList />} />
          <Route path="brand-upload" element={<BrandUpload />} />
          <Route path="brand-edit" element={<BrandEdit />} />
          <Route path="category-list" element={<CategoryList />} />
          <Route path="category-upload" element={<CategoryUpload />} />
          <Route path="category-edit" element={<CategoryEdit />} />
          <Route path="forms-list" element={<FormsList />} />
          <Route path="invoice-details" element={<InvoiceDetails />} />
          <Route path="order-list" element={<OrderList />} />
          <Route path="order-view" element={<OrderView />} />
          <Route path="message" element={<Message />} />
          <Route path="notification" element={<Notification />} />
          <Route path="settings" element={<Settings />} />
          <Route path="pages" element={<Pages />} />
          <Route path="blog-list" element={<BlogList />} />
          <Route path="blog-upload" element={<BlogUpload />} />
          <Route path="blog-edit" element={<BlogEdit />} />
          <Route path="mail-list" element={<MailList />} />

          {/* Supports Pages */}
          <Route path="*" element={<Error />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default TheRouter;
