import * as yup from "yup"

export const addReviewValidationSchema = yup.object({
  content: yup.string('Enter your content')
  .required('Content is required'),
  rating: yup.string('Enter rating')
})

export const addReviewInitialValues={
  rating:"",
  content:"",
}