import * as yup from "yup"

export const registerValidationSchema = yup.object({
  email:yup.string('Enter your email')
  .email('Enter a valid email')
  .required('Email is required'),
  firstName:yup.string('Enter your First Name')
  .required('First Name is required'),
  lastName:yup.string('Enter your Last Name')
  .required('Last Name is required'),
  phoneNumber:yup.string('Enter your Phone Number')
  .required('Phone Number is required'),
  password: yup.string('Enter your password')
  .min(8, 'Password should be of minimum 8 characters length')
  .required('Password is required'),
  confirmPassword:yup.string('Confirm Password').oneOf([yup.ref('password'), null], 'Passwords must match')
  .required('Confirm Password is required'),
})

export const registerInitialValues={
  email:"",
  firstName:"",
  lastName:"",
  phoneNumber:"",
  password:"",
  confirmPassword:""
}