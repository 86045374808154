import { CategoryRounded, RateReviewRounded, SearchRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Pagination } from "../../components";
import { CardHeader, CardLayout, FloatCard } from "../../components/cards";
import Anchor from "../../components/elements/Anchor";
import { MuiField } from "../../components/elements/MuiField";
import ProductsTable from "../../components/tables/ProductsTable";
import data from "../../data/master/productList.json";
import PageLayout from "../../layouts/PageLayout";
import { getCategory } from "../../store/categoriesSlice";
import { getProductsCounter } from "../../store/productsCounterSlice";
import { getProductsList } from "../../store/productsListSlice";
import BrandTable from "../../components/tables/BrandTable";
import CategoryTable from "../../components/tables/CategoryTable";
import { getCategoriesList } from "../../store/categoriesListSlice";
import axios from "axios";

export default function CategoryList() {
  const [category, setCategory] = useState("")
  const [rating, setRating] = useState("")
  const [search, setSearch] = useState("")
  const { productsList } = useSelector((state) => state.productsList)
  const { categoriesList } = useSelector((state) => state.categoriesList)
  const { productsCounter, categoriesCounter, usersCounter, soldCounter, brandsCounter } = useSelector((state) => state.productsCounter)
  const dispatch = useDispatch();
  const [categoryy, setCategoryy] = useState([]);
  const [brand, setBrand] = useState([]);
  const { token } = useSelector((state) => state.auth);

  const handleChangeCategory = async (e) => {
    let cat = e.target.value;
    setCategory(cat)
    dispatch(getCategoriesList({ category: cat, rating, search }))
  }

  const handleChangeRating = async (e) => {
    let rate = e.target.value;
    setRating(rate)
    dispatch(getProductsList({ category, rating: rate, search }))
  }

  const handleChangeSearch = async (e) => {
    let s = e.target.value;
    setSearch(s)
    dispatch(getCategoriesList({ category, rating, search: s }))
  }

  const getAllCatandBrand = async () => {
    let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/categories-brands`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setCategoryy(res.data.categories.map((cat) => {
      return cat.name
    }));
    setBrand(res.data.brands
      .map((brand) => {
      return brand.name
    }))
  }


  useEffect(() => {
    // getAllCatandBrand();
    // dispatch(getProductsList())
    dispatch(getProductsCounter())
    dispatch(getCategoriesList());

  }, [dispatch])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Category List"}>
              <li className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </li>
              <li className="mc-breadcrumb-item">
                Category
              </li>
              <li className="mc-breadcrumb-item">
                List
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg blue"}
            digit={categoriesCounter}
            title={"total Category"}
            icon={"shopping_bag"}
          />
        </Col>
        {/* <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg green"}
            digit={categoriesCounter}
            title={"Number of Stock"}
            icon={"widgets"}
          />
        </Col>
        <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg red"}
            digit={0}
            title={"Number of Sold"}
            icon={"widgets"}
          />
        </Col> */}
        {/* <Col sm={12} lg={3}>
          <FloatCard
            variant={"lg red"}
            digit={0}
            title={"Total Sold Product"}
            icon={"widgets"}
          />
        </Col> */}
        <Col xl={12}>
          <CardLayout>
            <CardHeader title={"Category"} />
            <Row>
              {/* <Col xs={12} sm={6} md={4} lg={4}>
                <MuiField
                  placeholder="Category By"
                  select={true}
                  value={category}
                  icon={<RateReviewRounded />}
                  onChange={handleChangeCategory}
                  data={categoryy}
                />
              </Col> */}
              <Col xs={12} sm={6} md={4} lg={4}>
                <MuiField
                  placeholder="Search..."
                  type={"search"}
                  value={search}
                  icon={<SearchRounded />}
                  onChange={handleChangeSearch}
                />
              </Col>

              <Col xl={12}>
                <CategoryTable
                  thead={data?.product.thead}
                  tbody={categoriesList}
                />
                {categoriesList && categoriesList.length > 10 && <Pagination />}
              </Col>
            </Row>
          </CardLayout>
        </Col>
      </Row>

    </PageLayout>
  );
}
