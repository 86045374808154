import React from "react";
import { Box, Icon, Input, Label, Text } from "./elements";

export default function FileUpload({ icon, text , onChange }) {
  return (
    <>
      {text ? (
        <Box className={`mc-file-upload ${text ? "button" : "icon"}`}>
          <Input type="file" id="avatar" onChange={onChange}/>
          <Label htmlFor="avatar">
            <Icon>{icon || "cloud_upload"}</Icon>
            <Text as="span">{text || "upload"}</Text>
          </Label>
        </Box>
      ) : (
        <Box className={`mc-file-upload ${text ? "button" : "icon"}`}>
          <Input type="file" id="avatar" />
          <Label htmlFor="avatar" className="material-icons">
            {icon || "cloud_upload"}
          </Label>
        </Box>
      )}
    </>
  );
}
