import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./authSlice"
import blogReducer from "./blogSlice"
import blogsListReducer from "./blogsListSlice"
import categoriesReducer from "./categoriesSlice"
import brandsReducer from "./brandSlice"
import brandsListReducer from "./brandsListSlice"
// import couponsListReducer from "./couponsListSlice"
import emailsCounterReducer from "./orderCounterSlice"
import emailsSentListReducer from "./emailsSentListSlice"
import ordersListReducer from "./ordersListSlice"
import formsListReducer from "./formsListSlice"
import popularClientsListReducer from "./popularClientsListSlice."
import productReducer from "./productSlice"
import productsCounterReducer from "./productsCounterSlice"
import productsListReducer from "./productsListSlice"
import categoriesListReducer from "./categoriesListSlice"
import recentActivitiesListReducer from "./recentActivitiesListSlice"
import userReducer from "./userSlice"
import usersCounterReducer from "./usersCounter"
import usersListReducer from "./usersListSlice"
import orderReducer from "./orderSlice"

export const store = configureStore({
  reducer: {
    "auth": authReducer,
    "user": userReducer,
    "productsList": productsListReducer,
    "categoriesList": categoriesListReducer,
    "product": productReducer,
    "productsCounter": productsCounterReducer,
    "category": categoriesReducer,
    "brands": brandsReducer,
    "brandsList": brandsListReducer,
    "usersCounter": usersCounterReducer,
    "usersList": usersListReducer,
    "blogsList": blogsListReducer,
    // "couponsList":couponsListReducer,
    "blog": blogReducer,
    // "coupon":couponReducer,
    "popularClientsList": popularClientsListReducer,
    "recentActivitiesList": recentActivitiesListReducer,
    "emailsSentList": emailsSentListReducer,
    "orderCounter": emailsCounterReducer,
    "ordersList": ordersListReducer,
    "order": orderReducer,
    "formsList": formsListReducer,
  },
})