import { InputAdornment } from "@mui/material";
import React from "react";
import { PrimaryTextField } from "../../mui/PrimaryTextField";

export const MuiField = ({ variant,placeholder,icon,name, type, label, formik ,rows,select,data,onChange,value}) => {
  return !formik ? (select ? (
    <PrimaryTextField
      fullWidth
      variant={variant || "outlined"}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      InputProps={icon && {
        startAdornment: (
          <InputAdornment position="start">
            {icon}
          </InputAdornment>
        ),
      }}
      select
      SelectProps={{
        native: true,
      }}
    >
      <option key={-1} value={""}>
        {label || placeholder}
      </option>
      {
        data && data.map((d,i)=>(
          <option key={i} value={d}>
            {d}
          </option>
        ))
      }
    </PrimaryTextField>
  ):(
    <PrimaryTextField
      fullWidth
      InputProps={icon && {
        startAdornment: (
          <InputAdornment position="start">
            {icon}
          </InputAdornment>
        ),
      }}
      variant={variant || "outlined"}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={onChange}
      multiline={Boolean(rows)}
      rows={rows}
    />
  )) :  (select ? (
    <PrimaryTextField
      fullWidth
      variant={variant || "outlined"}
      InputProps={icon && {
        startAdornment: (
          <InputAdornment position="start">
            {icon}
          </InputAdornment>
        ),
      }}
      id={name}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      select
      SelectProps={{
        native: true,
      }}
    >
      <option key={-1} value={""}>
        {label || placeholder}
      </option>
      {
        data && data.map((d,i)=>(
          <option key={i} value={d}>
            {d}
          </option>
        ))
      }
    </PrimaryTextField>
  ):(
    <PrimaryTextField
      fullWidth
      variant={variant || "outlined"}
      InputProps={icon && {
        startAdornment: (
          <InputAdornment position="start">
            {icon}
          </InputAdornment>
        ),
      }}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      type={type}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      multiline={Boolean(rows)}
      rows={rows}
    />
  ))
};
