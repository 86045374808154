import * as yup from "yup"

export const editBrandValidationSchema = yup.object({
    name:yup.string('Enter your Brand Name')
    .required('Brand Name is required'),
})

export const editBrandInitialValues={
    name:"",
    images:null,
}