import { ClearRounded, DoneRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Breadcrumb, DivideTitle } from "../../components";
import CardLayout from "../../components/cards/CardLayout";
import {
    Anchor,
    Box,
    Button,
    Heading,
    Icon,
    Image,
    Item,
    Text
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import CustomerReview from "../../components/review/CustomerReview";
import { handleAlert } from "../../functions/handleAlert";
import { handleDate } from "../../functions/handleDate";
import PageLayout from "../../layouts/PageLayout";
import { addReviewInitialValues, addReviewValidationSchema } from "../../schemas/AddReview";
import { getProduct } from "../../store/productSlice";
import { getOrder } from "../../store/orderSlice";


export default function OrderView() {
    const { order, isLoading } = useSelector((state) => state.order);

    const [ratingModal, setRatingModal] = useState("")
    const [productId, setProductId] = useState(null)
    const { token } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    // const handleReviewRating = () => {
    //     if (addReviewFormik.values.content) {
    //         setRatingModal(true)
    //         setProductId(product._id)
    //     } else {
    //         handleAlert("Write Review Content")
    //     }
    // }

    const addReviewFormik = useFormik({
        initialValues: addReviewInitialValues,
        validationSchema: addReviewValidationSchema,
        onSubmit: async (values, { resetForm }) => {
            await axios
                .post(`${process.env.REACT_APP_SERVER_URL}/v1/addReview/${productId}`, values, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((res) => {
                    handleAlert(res.data.message, "success");
                    dispatch(getProduct({ id }))
                    setRatingModal(false)
                    resetForm();
                })
                .catch((err) => {
                    handleAlert(err.response.data.message, "error");
                });
        },
    });

    useEffect(() => {
        dispatch(getOrder({ id }))
        // dispatch(getProduct({ id }))
    }, [dispatch, id])

    return (
        <PageLayout>
            <CardLayout className="mb-4">
                <Breadcrumb title={"Order View"}>
                    <Item className="mc-breadcrumb-item">
                        <Anchor className="mc-breadcrumb-link" href={"/"}>
                            Home
                        </Anchor>
                    </Item>
                    <Item className="mc-breadcrumb-item">
                        Order
                    </Item>
                    <Item className="mc-breadcrumb-item">
                        View
                    </Item>
                </Breadcrumb>
            </CardLayout>
            <CardLayout className="p-lg-5">
                {
                    !isLoading && (
                        <Row>
                            <Col xl={12}>
                                <DivideTitle title="Information" className="mb-4" />
                                <Box className="mc-product-view-info-group">
                                    <Heading as="h2" className="mc-product-view-info-title">
                                        {order?.name}
                                    </Heading>
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"pix"} />
                                        <Heading as="h5">{"Email Address"}</Heading>
                                        <Text as="span">:</Text>
                                        <Text as="p">
                                            {order?.email}
                                        </Text>
                                    </Box>
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"pix"} />
                                        <Heading as="h5">{"Phone"}</Heading>
                                        <Text as="span">:</Text>
                                        <Text as="p">
                                            {order?.phone}
                                        </Text>
                                    </Box>
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"sell"} />
                                        <Heading as="h5">{"Request :"}</Heading>
                                        <Text as="span">:</Text>
                                        <Text as="p">
                                            {order?.category}
                                        </Text>
                                    </Box>
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"shopping_cart"} />
                                        <Heading as="h5">{"status"}</Heading>
                                        <Text as="span">:</Text>
                                        <Text as="p">
                                            {order?.status}
                                        </Text>
                                    </Box>

                                    {/*<Box className="mc-product-view-meta">
                                        <Icon type={"shopping_basket"} />
                                        <Heading as="h5">{"Hours"}</Heading>
                                        <Text as="span">:</Text>
                                        <Text as="p">
                                            {`${order.product?.hours}`}
                                        </Text>
                                    </Box>
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"pix"} />
                                        <Heading as="h5">{"years"}</Heading>
                                        <Text as="span">:</Text>
                                        <Text as="p">
                                            {order.product?.year}
                                        </Text>
                                    </Box>
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"discount"} />
                                        <Heading as="h5">{"model"}</Heading>
                                        <Text as="span">:</Text>
                                        {order.product?.model}
                                    </Box>

                                    <Box className="mc-product-view-meta">
                                        <Icon type={"shopping_basket"} />
                                        <Heading as="h5">{"serial"}</Heading>
                                        <Text as="span">:</Text>
                                        {order.product?.serial}
                                    </Box>
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"map"} />
                                        <Heading as="h5">{"location"}</Heading>
                                        <Text as="span">:</Text>
                                        {order.product?.location}
                                    </Box> */}
                                    <Box className="mc-product-view-meta">
                                        <Icon type={"verified"} />
                                        <Heading as="h5">{"Created"}</Heading>
                                        <Text as="span">:</Text>
                                        <Text as="p">
                                            {handleDate(order?.createdAt)}
                                        </Text>
                                    </Box>
                                </Box>
                            </Col>
                            <Col xl={12} className=" mb-5">
                                <DivideTitle title="Message :" />
                                <Box className="mc-product-view-descrip">
                                    <Text>{order?.message}</Text>
                                </Box>
                            </Col>
                            {order.product && <>
                                <Col xl={5}>
                                    <DivideTitle title="product gallery" className="mb-4" />
                                    <Box className="mc-product-view-gallery">
                                        {order.product && order?.product.images.map((item, index) => (
                                            <Image key={index} src={`${process.env.REACT_APP_SERVER_URL}/${item}`} alt={"product"} />
                                        ))}
                                        {/* <Image src={product.imageCover} alt={"product Cover"} /> */}
                                    </Box>
                                </Col>
                                <Col xl={7}>
                                    <DivideTitle title="product details" className="mb-4" />
                                    <Box className="mc-product-view-info-group">
                                        <Heading as="h2" className="mc-product-view-info-title">
                                            {order?.product.name}
                                        </Heading>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"pix"} />
                                            <Heading as="h5">{"Category"}</Heading>
                                            <Text as="span">:</Text>
                                            <Text as="p">
                                                {order.product?.category}
                                            </Text>
                                        </Box>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"pix"} />
                                            <Heading as="h5">{"Brand"}</Heading>
                                            <Text as="span">:</Text>
                                            <Text as="p">
                                                {order.product?.brand}
                                            </Text>
                                        </Box>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"sell"} />
                                            <Heading as="h5">{"Price"}</Heading>
                                            <Text as="span">:</Text>
                                            <Text as="p">
                                                {order.product?.price}
                                            </Text>
                                        </Box>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"shopping_cart"} />
                                            <Heading as="h5">{"Stock"}</Heading>
                                            <Text as="span">:</Text>
                                            <Text as="p">
                                                {order.product.sold ? "No" : "Yes"}
                                            </Text>
                                        </Box>

                                        <Box className="mc-product-view-meta">
                                            <Icon type={"shopping_basket"} />
                                            <Heading as="h5">{"Hours"}</Heading>
                                            <Text as="span">:</Text>
                                            <Text as="p">
                                                {`${order.product?.hours}`}
                                            </Text>
                                        </Box>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"pix"} />
                                            <Heading as="h5">{"years"}</Heading>
                                            <Text as="span">:</Text>
                                            <Text as="p">
                                                {order.product?.year}
                                            </Text>
                                        </Box>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"discount"} />
                                            <Heading as="h5">{"model"}</Heading>
                                            <Text as="span">:</Text>
                                            {order.product?.model}
                                        </Box>

                                        <Box className="mc-product-view-meta">
                                            <Icon type={"shopping_basket"} />
                                            <Heading as="h5">{"serial"}</Heading>
                                            <Text as="span">:</Text>
                                            {order.product?.serial}
                                        </Box>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"map"} />
                                            <Heading as="h5">{"location"}</Heading>
                                            <Text as="span">:</Text>
                                            {order.product?.location}
                                        </Box>
                                        <Box className="mc-product-view-meta">
                                            <Icon type={"verified"} />
                                            <Heading as="h5">{"Published"}</Heading>
                                            <Text as="span">:</Text>
                                            <Text as="p">
                                                {handleDate(order.product?.createdAt)}
                                            </Text>
                                        </Box>
                                    </Box>
                                </Col>
                                <Col xl={12}>
                                    <DivideTitle title="product description" className="mt-5 mb-4" />
                                    <Box className="mc-product-view-descrip">
                                        <Text>{order.product?.description}</Text>
                                    </Box>
                                </Col>
                            </>}


                            {/* <Col xl={12}>
                <DivideTitle title="customer reviews" className="mt-5 mb-4" />
                <CustomerReview reviews={reviews} />
              </Col> */}
                            {/* <Col xl={12}>
                <DivideTitle title="Add review" className="mt-3 mb-4" />
                <MuiField
                  rows={4}
                  id={"content"}
                  name={"content"}
                  label={"Write Here..."}
                  formik={addReviewFormik}
                />
                <Button className="mc-btn mc-review-form-btn primary" onClick={handleReviewRating}>
                  drop your replies
                </Button>
              </Col> */}
                        </Row>
                    )
                }
            </CardLayout>
            {/* <Modal show={ratingModal} onHide={() => setRatingModal(false)}>
        <form onSubmit={addReviewFormik.handleSubmit} className="mc-discount-modal">
          <Icon type="stars" />
          <Heading as="h3">Add Product</Heading>
          <Text as="p">Choose Rate for Product</Text>
          <Modal.Body>
            <MuiField
              select={true}
              label={"Choose Rate"}
              id={"rating"}
              name={"rating"}
              data={[0, 1, 2, 3, 4, 5]}
              formik={addReviewFormik}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              className="btn btn-primary"
            >
              Add Review
            </Button>
          </Modal.Footer>
        </form>
      </Modal> */}
        </PageLayout>
    );
}
