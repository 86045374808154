"use client";
import { TextField, styled } from "@mui/material";

export const PrimaryTextField = styled(TextField)(({ theme }) => ({
    "& input , & select":{
     padding:"10px",
     backgroundColor:theme.palette.white_alt,
     alignItems:"flex-start",
    },
    "& div":{
        backgroundColor:theme.palette.white_alt,
    },
    "& input::placeholder":{
        fontSize: "18px",
    },
    "& svg": {
        fontSize: "22px",
    },
    [theme.breakpoints.down("lg")]: {
        "& input , & select":{
            padding:"9px",
           },
           "& input::placeholder":{
               fontSize: "17px",
           },
           "& svg": {
               fontSize: "20px",
           },
    },
    [theme.breakpoints.down("md")]: {
        "& input , & select":{
            padding:"8px",
           },
           "& input::placeholder":{
               fontSize: "16px",
           },
           "& svg": {
               fontSize: "18px",
           },
    },
    [theme.breakpoints.down("sm")]: {
        "& input , & select":{
            padding:"7px",
           },
           "& input::placeholder":{
               fontSize: "15px",
           },
           "& svg": {
               fontSize: "16px",
           },
    },
    [theme.breakpoints.down("sx")]: {
        "& input , & select":{
            padding:"6px",
           },
           "& input::placeholder":{
               fontSize: "14px",
           },
           "& svg": {
               fontSize: "16px",
           },
    },
}));