import { DiscountRounded, PriceChangeRounded, ShopRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
  Anchor,
  Box,
  Button,
  Icon,
  Image,
  Input,
  Label,
  Text
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/productUpload.json";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { addProductInitialValues, addProductValidationSchema } from "../../schemas/AddProduct";
import { getProduct } from "../../store/productSlice";
import { getCategoriesList } from "../../store/categoriesListSlice";
import { getBrandsList } from "../../store/brandsListSlice";

export default function ProductEdit() {

  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { categoriesList } = useSelector((state) => state.categoriesList);
  const { brandsList } = useSelector((state) => state.brandsList);

  const { product } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const [bool, setBool] = useState(true);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const addProductFormik = useFormik({
    initialValues: addProductInitialValues,
    validationSchema: addProductValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (!values.images) {
        handleAlert("Please Choose Product Images", "erorr")
        return
      }
      const formData = new FormData();
      if (values.images[0] instanceof File) {
        console.log('add new img');
        new Array(values.images.length).fill(0).map((_, i) => {
          formData.append(`images`, values.images[i]);
          return 0;
        });
      }

      // formData.append('name', values.name);
      formData.append('year', values.year);
      formData.append('hours', values.hours);
      formData.append('model', values.model);
      formData.append('serial', values.serial);
      formData.append('price', values.price);
      formData.append('location', values.location);
      formData.append('brand', values.brand);
      formData.append('category', values.category);
      formData.append('description', values.description);

      // if(values.imageCover instanceof File){
      //   formData.append('imageCover', values.imageCover);
      // }
      await axios
        .put(`${process.env.REACT_APP_SERVER_URL}/products/${product.productData?._id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          navigate(`${process.env.REACT_APP_PRODUCTS_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
          setIsLoading(true);
        });
    },
  });

  const handleFileOrUrl = (src) => {

    return src instanceof File ? URL.createObjectURL(src) : src
  }

  useEffect(() => {
    dispatch(getProduct({ id }));
    dispatch(getCategoriesList());
    dispatch(getBrandsList());
    // setTimeout(() => {
    //   console.log(brandsList);
    //   console.log(categoriesList);
    //   setCategory(categoriesList?.map((cat) => {
    //     return cat.name
    //   }));
    //   setBrand(brandsList?.map((brand) => {
    //     return brand.name
    //   }))
    // }, 2000)
    console.log(id);
  }, [dispatch])


  useEffect(() => {
    dispatch(getCategoriesList());

  }, [dispatch])

  useEffect(() => {
    if (product) {
      setBool(!bool)
      // addProductFormik.values.name = product && product.productData?.name
      addProductFormik.values.year = product && product.productData?.year
      addProductFormik.values.hours = product && product.productData?.hours
      addProductFormik.values.model = product && product.productData?.model
      addProductFormik.values.serial = product && product.productData?.serial
      addProductFormik.values.price = product && product.productData?.price
      addProductFormik.values.location = product && product.productData?.location
      addProductFormik.values.brand = product && product.productData?.brand
      addProductFormik.values.category = product && product.productData?.category
      addProductFormik.values.description = product && product.productData?.description
      addProductFormik.values.images = product && product.productData?.images.map((e) => {
        return `${process.env.REACT_APP_SERVER_URL}/${e}`
      })

      // addProductFormik.values.images = product && product?.images
      // addProductFormik.values.availableStock= product && product?.availableStock
      // addProductFormik.values.imageCover = product && product?.imageCover
      // addProductFormik.values.discountPercentage = product && product?.discountPercentage
    }
  }, [product])

  return (
    <PageLayout>
      <form onSubmit={addProductFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Product Edit"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  Product
                </li>
                <li className="mc-breadcrumb-item">
                  Edit
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="basic information" />
              <Row>
                {/* <Col xl={12}>
                  <MuiField
                    name="name"
                    label="Product Name"
                    type="text"
                    formik={addProductFormik}
                  />
                </Col> */}
                <Col xl={6}>
                  <MuiField
                    name="year"
                    label="year"
                    icon={<DiscountRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="hours"
                    label="Hours"
                    icon={<DiscountRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={4}>
                  <MuiField
                    name="price"
                    label="Price"
                    icon={<PriceChangeRounded />}
                    type="number"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={4}>
                  <MuiField
                    name="serial"
                    label="Serial Number"
                    icon={<DiscountRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={4}>
                  <MuiField
                    name="model"
                    label="Model"
                    icon={<ShopRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={12}>
                  <MuiField
                    name="location"
                    label="Location"
                    icon={<ShopRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={12}>
                  <MuiField
                    name="description"
                    label="Description"
                    type="text"
                    rows={4}
                    formik={addProductFormik}
                  />
                </Col>

                <Col xl={6}>
                  <MuiField
                    name="category"
                    label="Add Category"
                    type="text"
                    select={true}
                    formik={addProductFormik}
                    // data={categoriesList && Object.keys(categoriesList)}
                    data={categoriesList && categoriesList.map((cat)=>cat.name)}
                    // data={category}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="brand"
                    label="Add Brand"
                    type="text"
                    select={true}
                    formik={addProductFormik}
                    // data={brand}
                  // data={brandsList && Object.keys(brandsList)}
                  data={brandsList && brandsList.map((brand)=>brand.name)}
                  />
                </Col>
              </Row>
            </CardLayout>
          </Col>
          <Col xl={6}>
            <CardLayout>
              <CardHeader
                title="Product Images"
              />
              <Box className="mc-product-upload-media">
                <Box className="mc-product-upload-file">
                  <Input
                    type="file"
                    id="images"
                    multiple
                    onChange={(event) => {
                      addProductFormik.setFieldValue('images', event.currentTarget.files);
                    }}
                  />
                  <Label htmlFor="images">
                    <Icon type="collections" />
                    <Text className={`${addProductFormik.values.images && "whatsapp"}`}>{addProductFormik.values.images ? "Upload Successfully!!" : "Upload Images"}</Text>
                  </Label>
                </Box>
              </Box>
            </CardLayout>
          </Col>
          {/* <Col xl={6}>
            <CardLayout>
              <CardHeader
                title="Product Image Cover"
              />
              <Box className="mc-product-upload-media">
                <Box className="mc-product-upload-file">
                  <Input
                    type="file"
                    id="imageCover"
                    onChange={(event)=>{                
                      addProductFormik.setFieldValue('imageCover', event.currentTarget.files[0]);
                    }}
                  />
                  <Label htmlFor="imageCover">
                    <Icon type="collections" />
                    <Text className={`${addProductFormik.values.imageCover && "whatsapp"}`}>{addProductFormik.values.imageCover ? "Upload Successfully!!" :"Upload Cover"}</Text>
                  </Label>
                </Box>
              </Box>
            </CardLayout>
          </Col> */}
          <Col xl={6}>
            {addProductFormik.values.images && (
              <CardLayout>
                <CardHeader
                  title="Chosen Images"
                />
                <Box className={`grid jcs aifs g10 images_box_2`}>
                  {
                    new Array(addProductFormik.values.images.length).fill(0).map((_, i) => {
                      return (
                        <Image key={i} src={`${handleFileOrUrl(addProductFormik.values.images[i])}`} alt={"images"} className={`image`} />
                      )
                    })
                  }
                </Box>
              </CardLayout>
            )}
          </Col>
          <Col xl={6}>
            {addProductFormik.values.imageCover && (
              <CardLayout>
                <CardHeader
                  title="Chosen Image Cover"
                />
                <Image src={handleFileOrUrl(addProductFormik.values.imageCover)} alt={"conver"} className={`wid100`} />
              </CardLayout>
            )}
          </Col>
          <Col xl={12}>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={"submit"}
            >
              {isLoading ? <Spinner animation="grow" /> : "Edit Product"}
            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
