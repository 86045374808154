import { SearchRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Pagination } from "../../components";
import { Blog } from "../../components/Blog.js";
import { CardLayout, FloatCard } from "../../components/cards";
import Anchor from "../../components/elements/Anchor";
import { MuiField } from "../../components/elements/MuiField.js";
import PageLayout from "../../layouts/PageLayout";
import { getBlogsList } from "../../store/blogsListSlice.js";

export default function BlogList() {
  const {blogsList} = useSelector((state)=>state.blogsList)
  const dispatch = useDispatch()
  const [search , setSearch] = useState("")

  const handleChangeSearch = async(e)=>{
    let s=  e.target.value;
    setSearch(s)
    dispatch(getBlogsList({search:s}))
  }


  useEffect(()=>{
    dispatch(getBlogsList())
  },[dispatch])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"Blog List"}>
              <li className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </li>
              <li className="mc-breadcrumb-item">
                Blog
              </li>
              <li className="mc-breadcrumb-item">
                List
              </li>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col sm={12} lg={12}>
          <FloatCard
            variant={"lg blue"}
            digit={blogsList && blogsList.length}
            title={"total blogs"}
            icon={"article"}
          />
        </Col>
        <Col lg={12}>
          <MuiField
            placeholder="Search..."
            type={"search"}
            value={search}
            icon={<SearchRounded/>}
            onChange = {handleChangeSearch}
          />
        </Col>
        {
          blogsList && blogsList.map((blog,i)=>(
            <Col xl={12} key={i}>
              <CardLayout>
                <Blog blog={blog} key={i}/>
              </CardLayout>
            </Col>
          ))
        }
        <Col xl={12}>
          {blogsList && blogsList.length > 10 && <Pagination />}
        </Col>
      </Row>
    </PageLayout>
  );
}
