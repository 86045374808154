import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "../../components";
import {
  ActivityCard,
  ClientsCard,
  EcommerceCard,
  OrdersCard,
  ProductsCard,
  RevenueCard,
  SalesCard
} from "../../components/cards";
import { Anchor, Box, Item } from "../../components/elements";
import data from "../../data/master/ecommerce.json";
import PageLayout from "../../layouts/PageLayout";
import { getPopularClientsList } from "../../store/popularClientsListSlice.";
import { getProductsCounter } from "../../store/productsCounterSlice";
import { getRecentActivitiesList } from "../../store/recentActivitiesListSlice";
import { getUsersCounter } from "../../store/usersCounter";
import Cookies from 'js-cookie'

export default function Ecommerce() {

  function formatNumberToCurrency(num) {
    // Create a new Intl.NumberFormat object for US currency
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    });

    // Format the number using the formatter
    return formatter.format(num).replace('$', '');
  }

  // const {usersCounter} = useSelector((state)=>state.usersCounter)
  const { productsCounter, categoriesCounter, brandsCounter, usersCounter, soldCounter,
    totalSales, totalOffers } = useSelector((state) => state.productsCounter)
  const dispatch = useDispatch();


  useEffect(() => {
    // dispatch(getUsersCounter())
    const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
    if (token) {
      dispatch(getProductsCounter())
    }
    // dispatch(getPopularClientsList())
    // dispatch(getRecentActivitiesList())
  }, [dispatch])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={data?.pageTitle}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">
                Dashboard
              </Item>
            </Breadcrumb>
          </Box>
        </Col>
        <Col xs={12} xl={8}>
          <Row xs={1} sm={2}>
            <Col>
              <EcommerceCard
                icon={"account_circle"}
                title={"total users"}
                number={usersCounter}
                variant={"green"}
                dotsMenu={{
                  "dots": "more_vert",
                  "dropdown": [
                    { "icon": "history", "text": "last day" },
                    { "icon": "history", "text": "last week" },
                    { "icon": "history", "text": "last month" },
                    { "icon": "history", "text": "last year" }
                  ]
                }}
              />
            </Col>
            <Col>
              <EcommerceCard
                icon={"shopping_bag"}
                title={"total products"}
                number={productsCounter}
                variant={"blue"}
                dotsMenu={{
                  "dots": "more_vert",
                  "dropdown": [
                    { "icon": "history", "text": "last day" },
                    { "icon": "history", "text": "last week" },
                    { "icon": "history", "text": "last month" },
                    { "icon": "history", "text": "last year" }
                  ]
                }}
              />
            </Col>
            <Col>
              <EcommerceCard
                icon={"category"}
                title={"total categories"}
                number={categoriesCounter}
                variant={"yellow"}
                dotsMenu={{
                  "dots": "more_vert",
                  "dropdown": [
                    { "icon": "history", "text": "last day" },
                    { "icon": "history", "text": "last week" },
                    { "icon": "history", "text": "last month" },
                    { "icon": "history", "text": "last year" }
                  ]
                }}
              />
            </Col>
            <Col>
              <EcommerceCard
                icon={"category"}
                title={"total Brands"}
                number={brandsCounter}
                variant={"purple"}
                dotsMenu={{
                  "dots": "more_vert",
                  "dropdown": [
                    { "icon": "history", "text": "last day" },
                    { "icon": "history", "text": "last week" },
                    { "icon": "history", "text": "last month" },
                    { "icon": "history", "text": "last year" }
                  ]
                }}
              />
            </Col>
            <Col>
              <EcommerceCard
                icon={"shopping_cart"}
                title={"total orders"}
                number={totalOffers}
                variant={"green"}
                dotsMenu={{
                  "dots": "more_vert",
                  "dropdown": [
                    { "icon": "history", "text": "last day" },
                    { "icon": "history", "text": "last week" },
                    { "icon": "history", "text": "last month" },
                    { "icon": "history", "text": "last year" }
                  ]
                }}
              />
            </Col>
            <Col>
              <EcommerceCard
                icon={"shopping_cart"}
                title={"total Sold"}
                number={soldCounter}
                variant={"blue"}
                dotsMenu={{
                  "dots": "more_vert",
                  "dropdown": [
                    { "icon": "history", "text": "last day" },
                    { "icon": "history", "text": "last week" },
                    { "icon": "history", "text": "last month" },
                    { "icon": "history", "text": "last year" }
                  ]
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} xl={4}>
          <SalesCard
            title={data?.sales.title}
            amount={`$${formatNumberToCurrency(totalSales)}`}
            percent={data?.sales.percent}
            trendIcon={data?.sales.trendIcon}
            dotsMenu={data?.sales.dotsMenu}
            compare={`$ ${formatNumberToCurrency(totalSales)} in last month`}
            chart={data?.sales.chart}
          />
        </Col>
        <Col xl={12}>
          <ProductsCard
            title={"Best Selling Products"}
          />
        </Col>
        {/* <Col xl={8}>
          <RevenueCard
            title={data?.revenue.title}
            field={data?.revenue.field}
            report={data?.revenue.report}
            chart={data?.revenue.chart}
          />
        </Col>
        <Col xl={4}>
          <OrdersCard
            title={data?.orders.title}
            dotsMenu={data?.orders.dotsMenu}
            items={data?.orders.items}
          />
        </Col>
         <Col xl={6}>
          <ClientsCard />
        </Col>
        <Col xl={6}>
          <ActivityCard
            title={"Recent Activities"}
          />
        </Col> */}
      </Row>
    </PageLayout>
  );
}
