import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Image,
  Text,
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/forgot.json";
import { handleAlert } from "../../functions/handleAlert";
import {
  forgotPasswordInitialValues,
  forgotPasswordValidationSchema,
} from "../../schemas/ForgotPassword";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const forgotPasswordFormik = useFormik({
    initialValues: forgotPasswordInitialValues,
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/ForgotPassword`, values)
        .then((res) => {
          navigate(`${process.env.REACT_APP_LOGIN_ROUTE}`);
          resetForm();
          handleAlert(res.data.message, "success");
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
        });
    },
  });
  return (
    <Box className="mc-auth">
      <Image
        className="mc-auth-pattern"
        src={data?.pattern.src}
        alt={data?.pattern.alt}
      />
      <Box className="mc-auth-group center_rel_y">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <form
          className="mc-auth-form grid jcs aic g30"
          onSubmit={forgotPasswordFormik.handleSubmit}
        >
          <MuiField
            name="email"
            label="Email"
            type="email"
            formik={forgotPasswordFormik}
          />
          <Button
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
          >
            {data?.button.text}
          </Button>
        </form>
        <Box className="mc-auth-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
        </Box>
      </Box>
    </Box>
  );
}
