

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getUsersList = createAsyncThunk("/users", async (args) => {
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
  let res;
  if (args) {
    const { role, search, status } = args
    // res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/${args.id}`, {
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // })
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users?limit=20&page=0&search=${search || ""}&role=${role || ""}&status=${status || ""}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  } else {
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users?limit=25&page=0`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
  return res.data
})

const initialState = {
  usersList: null,
  isLoading: true
}

export const usersListSlice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getUsersList.fulfilled, (state, { payload }) => {
      state.usersList = payload
      state.isLoading = false
    })
    builder.addCase(getUsersList.rejected, (_, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage, "error")
      } else {
        handleAlert(action.error.message, "error")
      }
    })
  }
})


export default usersListSlice.reducer