import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

export const getAccountData = createAsyncThunk("users",async(args)=>{
  const userId = Cookies.get(`${process.env.REACT_APP_USERID_NAME}`) 
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  // const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/65cca46a6a989b7fe4e2d3fc`,{
  // const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/65daf6dc03e22a9b1f04598a`,{
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/${userId}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  // console.log(userId);
  return res.data
})

const initialState = {
  accountData:null,
  isLoading:true,
  userId:null,
  token:null,
  signed:false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state,{payload}) => {
      Cookies.set(`${process.env.REACT_APP_TOKEN_NAME}`,payload.token)
      Cookies.set(`${process.env.REACT_APP_USERID_NAME}`,payload.userId)
      state.token =  payload.token
      state.userId =  payload.userId
      state.signed =  true
    },
    logout: (state) => {
      Cookies.remove(`${process.env.REACT_APP_TOKEN_NAME}`)
      Cookies.remove(`${process.env.REACT_APP_USERID_NAME}`)
      state.token =  null
      state.userId =  null
      state.signed =  false
    },
  },
  extraReducers:(builder)=>{
    builder.addCase(getAccountData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAccountData.fulfilled, (state, { payload }) => {
      state.accountData = payload
      state.isLoading = false
    })
    builder.addCase(getAccountData.rejected, (state, action) => {
      if (action.payload) {
        state.isLoading = true
        state.error = action.payload.errorMessage
      } else {
        state.error = action.error
      }
    })
  }
})

export const { login, logout } = authSlice.actions

export default authSlice.reducer