import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getOrdersList = createAsyncThunk("ordersList/getOrdersList",async(args)=>{
    const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
    let res;
    if(args){
      const {search,status,date} = args
      res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listOrders?limit=10&page=0&search=${search || ""}&status=${status || ""}&date=${date||""}`,{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })
    }else{
      res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/order?limit=10&page=0&search=&category=offer`,{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })
    }
    // console.log(res.data)
    return res.data
})

const initialState = {
  ordersList:null,
  isLoading:true
}

export const ordersListSlice = createSlice({
  name: 'ordersList',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getOrdersList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getOrdersList.fulfilled, (state, { payload }) => {
      state.ordersList = payload
      state.isLoading = false
    })
    builder.addCase(getOrdersList.rejected, (_, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})


export default ordersListSlice.reducer