

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getProductsList=createAsyncThunk("productsList/getProductsList",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  let res;
  if(args){
    const {category , search , rating} = args
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products?limit=&page=0&search=${search || ""}&category=${category || ""}&brand=${rating || ""}&model&year`,{
      headers:{
        Authorization:`Bearer ${token}`
      }
    })
  }else{
    // res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listProducts?limit=10&page=0&search=&category=&rating=`,{
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products?limit=&page=0&search&category=&brand=&model&year`,{
      headers:{
        Authorization:`Bearer ${token}`
      }
    })
  }
  // console.log(res.data);
  return res.data
})

const initialState = {
  productsList:null,
  isLoading:true
}

export const productsListSlice = createSlice({
  name: 'productsList',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getProductsList.pending, (state, { payload }) => {
      state.isLoading = true
    })
    builder.addCase(getProductsList.fulfilled, (state, { payload }) => {
      state.productsList = payload
      state.isLoading = false
    })
    builder.addCase(getProductsList.rejected, (_, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})


export default productsListSlice.reducer