import React from "react";
import { useSelector } from "react-redux";
import { handlePastDates } from "../../functions/handlePastDates";
import DivideTitle from "../DivideTitle";
import { Anchor, Box, Heading, Image, Item, List, Text } from "../elements";
import CardHeader from "./CardHeader";

export default function ActivityCard({ title, style }) {
  const {recentActivitiesList} = useSelector((state)=>state.recentActivitiesList)
  return (
    <Box className="mc-card">
      <CardHeader title={title} />
      <List className="mc-activity-card-list thin-scrolling" style={style}>
        {recentActivitiesList && recentActivitiesList.map((item, index) => (
          <Item key={index} className="mc-activity-card-item">
            <Box className="mc-activity-card-title">
              <DivideTitle as="h6" title={`${item.productName}`} />
              <Text as="small">{handlePastDates(item.updatedAt)}</Text>
            </Box>
            <Box className="mc-activity-card-body">
              <Text className="mc-activity-card-text">Created Successfully !!</Text>
              {item.media && (
                <Box className="mc-activity-card-media">
                  <Image src={item.media.src} alt={item.media.alt} />
                  <Heading as="h6">{item.media.name}</Heading>
                </Box>
              )}
              {item.button && (
                <Anchor href={item.button.path} className="mc-btn primary sm">
                  {item.button.label}
                </Anchor>
              )}
            </Box>
          </Item>
        ))}
      </List>
    </Box>
  );
}
