import * as yup from "yup"

export const editCategoryValidationSchema = yup.object({
    name:yup.string('Enter your Category Name')
    .required('Category Name is required'),
})

export const editCategoryInitialValues={
    name:"",
    images:null,
}