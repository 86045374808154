import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Table, Thead, Tbody, Th, Tr, Td } from "../elements/Table";
import {
  Image,
  Input,
  Text,
  Box,
  Icon,
  Button,
  Heading,
  Anchor,
} from "../elements";
import { AccountCircleRounded, SecurityRounded } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import { PrimaryTextField } from "../../mui/PrimaryTextField";
import axios from "axios";
import Cookies from "js-cookie";
import { handleAlert } from "../../functions/handleAlert";
import { useDispatch } from "react-redux";
import { getOrdersList } from "../../store/ordersListSlice";
import { getOrderCounter } from "../../store/orderCounterSlice";


export default function OrderTable({ thead, tbody }) {

  const dispatch = useDispatch();


  const [alertModal, setAlertModal] = React.useState(false);
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
  const [data, setData] = useState([]);
  const [orderId, setOrderId] = useState(null);


  // const {editableUserData, setEditableUserData} = useContext(UserContext)
  const [editModal, setEditModal] = React.useState(false);
  // const [role, setRole] = useState("")
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState("");

  const handleDeleteOrder = async () => {
    await axios.delete(`${process.env.REACT_APP_SERVER_URL}/order/${orderId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      // dispatch(getProductsList())
      // dispatch(getProductsCounter())
      dispatch(getOrdersList())
      dispatch(getOrderCounter())
      handleAlert(res.data.message, "success")
    }).catch((err) => {
      handleAlert(err.response.data.message, "error")
    })
    setAlertModal(false)
  }

  const handleCheckbox = (event) => {
    const { name, checked } = event.target;

    if (name === "allCheck") {
      const checkData = data?.map((item) => {
        return { ...item, isChecked: checked };
      });
      setData(checkData);
    } else {
      const checkData = data?.map((item) =>
        item.name === name ? { ...item, isChecked: checked } : item,
      );
      setData(checkData);
    }
  };

  useEffect(() => {
    setData(tbody);
  }, [tbody]);



  const handleOpenEditModal = (data) => {
    setEditModal(true);
    setOrder(data);
    // setEditableUserData(data)
  }

  const handleCloseChangeModal = () => {
    // setRole("")
    setStatus("");
    setEditModal(false);
  }

  const changeOrderStatus = async (e) => {
    e.preventDefault();
    await axios.patch(`${process.env.REACT_APP_SERVER_URL}/order/${order._id}`, {
      "status": status
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      handleAlert(res.data.message, "success");
      dispatch(getOrdersList());
      handleCloseChangeModal();
    }).catch((err) => {
      handleAlert(err.response.data.message, "error");
    })

  }

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                {/* <Input
                  type="checkbox"
                  name="allCheck"
                  checked={
                    data?.filter((item) => item.isChecked !== true).length < 1
                  }
                  onChange={handleCheckbox}
                /> */}
                <Text>uid</Text>
              </Box>
            </Th>
            {thead.map((item, index) => (
              <Th key={index}>{item}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {tbody && tbody?.map((item, index) => (
            <Tr key={index}>
              <Td title={index + 1}>
                {/* <Box className="mc-table-check">
                  <Text>#{index + 1}</Text>
                </Box> */}
                <Box className="mc-table-check">
                  {/* <Input
                    type="checkbox"
                    name={item.name}
                    checked={item?.isChecked || false}
                    onChange={handleCheckbox}
                  /> */}
                  <Text>#{index + 1}</Text>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-product md">
                  {/* <Image src={`${process.env.REACT_APP_SERVER_URL}/${item.image}`} alt={"Cover"} /> */}
                  <Box className="mc-table-group">
                    <Heading as="h6">{item.name}</Heading>
                    {/* <Text>{item.description}</Text> */}
                  </Box>
                </Box>
              </Td>

              <Td>{item.product}</Td>
              <Td>
                <Box className="mc-table-price">
                  <Text>{item.phone}</Text>
                </Box>
              </Td>
              <Td>{item.email}</Td>
              <Td>
                {/* اعمل كلاس بأسم كل استاتيس وظبط الاستايل بتاعوا  */}
                <Text className={`mc-table-badge ${item.status}`}
                  style={{
                    color: `${(item.status === "rejected") ? "#e00b2b" :
                      (item.status === "processing") ? "#0d6efd" :
                        (item.status === "shipping") ? "#c75a00" :
                          (item.status === "pending") ? "#8d6a00" :
                            (item.status === "approved") ? "#286d00" :
                              ''}`,
                    background: `${(item.status === "rejected") ? "#e8aeae" :
                      (item.status === "processing") ? "#9bc1f9" :
                        (item.status === "shipping") ? "#ffcca2" :
                          (item.status === "pending") ? "#f7eb87" :
                            (item.status === "approved") ? "#a9f181" :
                              ''}`
                  }}>
                  {item.status}
                </Text>
                {/* <Text className={`mc-table-badge ${(item.status == "pending")?`purple`:''}`}>
                  {item.status}
                </Text> */}
              </Td>
              {/* <Td>
              {item.status}
              </Td> */}
              <Td>
                <Box className="mc-table-rating">
                  <Heading>{item.createdAt.split('T')[0]}</Heading>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-rating">
                  <Heading>{item.category}</Heading>
                </Box>
              </Td>
              {/* <Td>
                <Box className="mc-table-action">
                  <Anchor
                    href={`/order-view?id=${item._id}`}
                    title="View"
                    className="material-icons view"
                  >
                    visibility
                  </Anchor>
                  <Button
                    title="Discount"
                    className="material-icons discount"
                    // onClick={() => {setCouponedModal(true);setProductId(item._id)}}
                  >
                    discount
                  </Button> 
                  <Anchor
                    href={`/brand-edit?id=${item._id}`}
                    title="Edit"
                    className="material-icons  edit"
                  >
                    edit
                  </Anchor>
                  <Button
                    title="Delete"
                    className="material-icons delete"
                    // onClick={() => {setAlertModal(true);setProductId(item._id)}}
                  >
                    delete
                  </Button>
                </Box>
              </Td> */}
              <Td>
                <Box className="mc-table-action">
                  <Anchor
                    href={`/order-view?id=${item._id}`}
                    title="View"
                    className="material-icons view"
                  >
                    visibility
                  </Anchor>
                  <Button
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => handleOpenEditModal(item)}
                  >
                    edit
                  </Button>
                  <Button
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => { setAlertModal(true); setOrderId(item._id) }}
                  >
                    delete
                  </Button>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>


      <Modal
        show={editModal}
        onHide={handleCloseChangeModal}
      >
        <form onSubmit={changeOrderStatus}>
          <Box className="mc-user-modal">
            <Image src="./images/logo.jpg" alt={"avatar"} />
            <Heading as="h4">Change Status :</Heading>
            <Text as="p"></Text>
            <Box className={`grid jcs aic g20`}>

              <PrimaryTextField
                fullWidth
                variant={"outlined"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SecurityRounded />
                    </InputAdornment>
                  ),
                }}
                required
                // value={status === "" ? editableUserData && editableUserData.status: status}
                onChange={(e) => setStatus(e.target.value)}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option key={-1} value={""}>
                  Status
                </option>
                <option value="pending">
                  Pending
                </option>
                <option value="processing">
                  Processing
                </option>
                <option value="shipping">
                  Shipping
                </option>
                <option value="approved">
                  Approved
                </option>
                <option value="rejected">
                  Rejected
                </option>
              </PrimaryTextField>
            </Box>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseChangeModal}
              >
                close popup
              </Button>
              <Button
                type="submit"
                className="btn btn-success"
              >
                save Changes
              </Button>
            </Modal.Footer>
          </Box>
        </form>
      </Modal>

      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to delete this order?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              // onClick={() => setAlertModal(false)}
              onClick={handleDeleteOrder}
            >
              yes, delete
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </Box>
  );
}
