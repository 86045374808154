import React from "react";
import { useSelector } from "react-redux";
import Box from "../elements/Box";
import ClientsTable from "../tables/ClientsTable";
import CardHeader from "./CardHeader";

export default function ClientsCard() {
  const {popularClientsList} = useSelector((state)=>state.popularClientsList)
  return (
    <Box className="mc-card">
      <CardHeader title={"Popular Clients"} />
      {popularClientsList && <ClientsTable tbody={popularClientsList}/>}
    </Box>
  );
}
