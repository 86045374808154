import { createContext, useEffect, useState } from "react";
import PulseLoader from "react-spinners/PulseLoader";
import { Box, Heading, Image } from "../components/elements";

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(()=> {
        setLoading(true);
        setTimeout(()=> { setLoading(false) }, 1000);
    }, []);

    return (
        <LoaderContext.Provider value={{ loading }}>
            { loading ? 
                <Box className="mc-spinner">
                    <Image src="/images/logo.png" aly="logo" />
                    <Box className="mc-spinner-group my-3">
                        <h3 style={{color:'#ffb400'}}>Loading</h3>
                        <PulseLoader 
                            color="#ffb400"
                            loading={loading} 
                            size={8} 
                        /> 
                    </Box>
                </Box>
                : 
                children 
            }
        </LoaderContext.Provider>
    )
}