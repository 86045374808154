import * as yup from "yup"



export const saveChangesValidationSchema = yup.object({
  name: yup.string('Enter your  Name')
    .required(' Name is required'),
  email: yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  phone: yup.string('Enter your Phone Number')
    .required('Phone Number is required'),
  role: yup.string('Enter your role')
    .required('Role is required'),
});
export const saveChangesInitialValues = {
  name: "",
  email: "",
  phone: "",
  role: "",
  image: null
}