import { AccountBalanceRounded, SearchRounded, SecurityRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Pagination } from "../../components";
import { CardHeader, CardLayout, FloatCard } from "../../components/cards";
import { Anchor, Item } from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import UsersTable from "../../components/tables/UsersTable";
import PageLayout from "../../layouts/PageLayout";
import { getUsersCounter } from "../../store/usersCounter";
import { getUsersList } from "../../store/usersListSlice";
import axios from "axios";
import Cookies from "js-cookie";

export default function UserList() {
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("");
  const [status, setStatus] = useState("");
  const [userDateType, setUserDateType] = useState("");

  const { usersList } = useSelector((state) => state.usersList);
  const { userTypes } = useSelector((state) => state.usersCounter);
  const dispatch = useDispatch();

  const handleChangeSearch = async (e) => {
    let s = e.target.value;
    setSearch(s)
    dispatch(getUsersList({ role, status, search: s }))
  }

  const handleChangeRole = async (e) => {
    let r = e.target.value;
    setRole(r)
    dispatch(getUsersList({ role: r, status, search }))
  }

  const handleChangeStatus = async (e) => {
    let s = e.target.value;
    setStatus(s)
    dispatch(getUsersList({ role, status: s, search }))
  }

  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
  
  const getUserType = async () => {
    
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/counters`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then((res) => {
      // console.log(res.data);
      setUserDateType(res.data);
    }).catch((error) => {
      console.log(error);
    })
    // console.log("add user");
  }

  useEffect(() => {
    getUserType();
  }, []);



  useEffect(() => {
    dispatch(getUsersList())
    // dispatch(getUsersCounter());
  }, [dispatch])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"User List"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">
                Users
              </Item>
              <Item className="mc-breadcrumb-item">
                All-User
              </Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
        {userDateType && userDateType.map((user, index) => {
          return (
            <Col sm={12} lg={3} key={index}>
              <FloatCard
                variant={"lg blue"}
                digit={user.count}
                title={user._id}
                icon={"shopping_bag"}
              />
            </Col>
          )
        })}

        <Col xl={12}>
          <CardLayout>
            <CardHeader title={"Users"} />
            <Row xs={1} sm={2} lg={3} className="mb-4">
              {/* <Col>
                <MuiField
                  placeholder="Role By"
                  select={true}
                  value={role}
                  icon={<AccountBalanceRounded />}
                  onChange={handleChangeRole}
                  data={["user", "admin"]}
                />
              </Col>
              <Col>
                <MuiField
                  placeholder="Status By"
                  select={true}
                  value={status}
                  icon={<SecurityRounded />}
                  onChange={handleChangeStatus}
                  data={["verified", "pending", "blocked"]}
                />
              </Col> */}
              <Col>
                <MuiField
                  placeholder="Search..."
                  value={search}
                  icon={<SearchRounded />}
                  onChange={handleChangeSearch}
                />
              </Col>
            </Row>
            {usersList && <UsersTable tbody={usersList} />}
            {usersList && usersList.length > 10 && <Pagination />}
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
