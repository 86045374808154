

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getBlogsList=createAsyncThunk("blogsList/getBlogsList",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  let res;
  if(args){
    const {search} = args
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listBlogs?limit=10&page=0&search=${search || ""}`,{
      headers:{
        Authorization:`Bearer ${token}`
      }
    })
  }else{
    res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listBlogs?limit=10&page=0&search=`,{
      headers:{
        Authorization:`Bearer ${token}`
      }
    })
  }
  return res.data.data
})

const initialState = {
  blogsList:null,
  isLoading:true
}

export const blogsListSlice = createSlice({
  name: 'blogsList',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getBlogsList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getBlogsList.fulfilled, (state, { payload }) => {
      state.blogsList = payload
      state.isLoading = false
    })
    builder.addCase(getBlogsList.rejected, (state, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})


export default blogsListSlice.reducer