import axios from "axios";
import { useFormik } from "formik";
import { Col, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
  Anchor,
  Box,
  Button,
  Icon,
  Image,
  Input,
  Label,
  Text,
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBrand } from "../../store/brandSlice";
import { editBrandInitialValues, editBrandValidationSchema } from "../../schemas/EditBrand";


export default function BrandEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [bool, setBool] = useState(true)
  const { brand } = useSelector((state) => state.brands)
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const editBrandFormik = useFormik({
    initialValues: editBrandInitialValues,
    validationSchema: editBrandValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (!values.images) {
        handleAlert("Please Choose Category Images", "erorr")
        return
      }

      const formData = new FormData();
      if (values.images[0] instanceof File) {
        new Array(values.images.length).fill(0).map((_, i) => {
          formData.append(`image`, values.images[i]);
          return 0;
        });
      }
      formData.append('name', values.name);

      await axios
        .put(`${process.env.REACT_APP_SERVER_URL}/brand/${id}`, formData ,  {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          navigate(`${process.env.REACT_APP_BRANDS_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
          setIsLoading(true);
        });
    },
  });

  useEffect(() => {

    dispatch(getBrand({ id }))
  }, [dispatch, id])

  useEffect(() => {
    if (brand) {

      setBool(!bool)
      editBrandFormik.values.name = brand && brand?.name
      // console.log(brand?.name);

      editBrandFormik.values.images = brand && `${process.env.REACT_APP_SERVER_URL}/${brand?.image}`
      // editBrandFormik.values.images = Object.keys(brand?.image).map((e) => {
      //   return `${e.toString()}`
      // })

    }
  }, [brand]);

  const handleFileOrUrl = (src) => {
    return src instanceof File ? URL.createObjectURL(src) : src
  }

  return (
    <PageLayout>
      <form onSubmit={editBrandFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Brand Edit"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  Brand
                </li>
                <li className="mc-breadcrumb-item">
                  Edit
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="basic information" />
              <Row>
                <Col xl={12}>
                  <MuiField
                    name="name"
                    id="name"
                    label="Brand Name"
                    type="text"
                    formik={editBrandFormik}
                  />
                </Col>
                {/* <Col xl={6}>
                  <MuiField
                    name="discountPercentage"
                    id="discountPercentage"
                    label="Discount Percentage"
                    type="number"
                    formik={editBrandFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    id="usageLimit"
                    name="usageLimit"
                    label="Usage Limit"
                    type="number"
                    formik={editBrandFormik}
                  />
                </Col>
                <Col xl={12}>
                  <Text>Expiration Date</Text>
                  <MuiField
                    id="expirationDate"
                    name="expirationDate"
                    type="date"
                    formik={editBrandFormik}
                  />
                </Col> */}
                <Col xl={6}>
                  <CardLayout>
                    <CardHeader
                      title="Brand Images"
                    />
                    <Box className="mc-product-upload-media">
                      <Box className="mc-product-upload-file">
                        <Input
                          type="file"
                          id="images"
                          multiple
                          onChange={(event) => {
                            editBrandFormik.setFieldValue('images', event.currentTarget.files);
                          }}
                        />
                        <Label htmlFor="images">
                          <Icon type="collections" />
                          <Text className={`${editBrandFormik.values.images && "whatsapp"}`}>{editBrandFormik.values.images ? "Upload Successfully!!" : "Upload Images"}</Text>
                        </Label>
                      </Box>
                    </Box>
                  </CardLayout>
                </Col>
                <Col xl={6}>
                  {editBrandFormik.values.images && (
                    <CardLayout>
                      <CardHeader
                        title="Chosen Images"
                      />
                      <Box className={`grid jcs aifs g10 images_box_2`}>
                        {/* {
                          new Array(editBrandFormik.values.images.length).fill(0).map((_, i) => {
                            return (
                              <Image key={i} src={`${handleFileOrUrl(editBrandFormik.values.images[i])}`} alt={"images"} className={`image`} />
                            )
                          })
                        } */}
                        <Image  src={`${handleFileOrUrl(editBrandFormik.values.images)}`} alt={"images"} className={`image`} />
                      </Box>
                    </CardLayout>
                  )}
                </Col>
              </Row>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <Button
              className={`mc-auth-btn h-sm`}
              type={"submit"}
            >
              {isLoading ? <Spinner animation="grow" /> : "Edit Brand"}
            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
