import React from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DuelText, RoundAvatar } from "..";
import { logout } from "../../store/authSlice";
import { Anchor } from "../elements";

export default function ProfileDropdown({ dropdown }) {
  const {accountData,isLoading} = useSelector((state)=>state.auth)
  const dispatch = useDispatch()
  const handleLogout =()=>{
    dispatch(logout())
  }
  return (
    accountData && !isLoading && <Dropdown className="mc-header-user">
      <Dropdown.Toggle className="mc-dropdown-toggle">
        <RoundAvatar src={`${process.env.REACT_APP_SERVER_URL}/${accountData.image}`} alt="avatar" size="xs" />
        <DuelText title={accountData.name} size="xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="mc-dropdown-paper">
        {dropdown.map((item, index) => (
          <Anchor
            key={index}
            href={item.path}
            icon={item.icon}
            text={item.text}
            onClick={index === 1 && handleLogout}
            className="mc-dropdown-menu"
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
