import { ClearRounded, DoneRounded } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { handleAlert } from "../../functions/handleAlert";
import { getProductsCounter } from "../../store/productsCounterSlice";
import { getProductsList } from "../../store/productsListSlice";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Text
} from "../elements";
import { Table, Tbody, Td, Th, Thead, Tr } from "../elements/Table";
import { getBrandsList } from "../../store/brandsListSlice";

export default function BrandTable({ tbody }) {
  const [alertModal, setAlertModal] = useState(false);
  const [couponedModal, setCouponedModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const {token} = useSelector((state)=>state.auth)
  const dispatch = useDispatch()

  const handleDeleteProduct=async()=>{
    await axios.delete(`${process.env.REACT_APP_SERVER_URL}/brand/${productId}`,{
      headers:{
        Authorization:`Bearer ${token}`
      }
    }).then((res)=>{
      dispatch(getBrandsList())
      dispatch(getProductsCounter())
      handleAlert(res.data.message,"success")
    }).catch((err)=>{
      handleAlert(err.response.data.message,"error")
    })
    setAlertModal(false)
  }

  const handleCouponedProduct=async(couponed)=>{
    // await axios.patch(`${process.env.REACT_APP_SERVER_URL}/dashboard/${couponed ? "removeCouponFromProduct" : "applyCouponOnProduct"}/${productId}`,{
    //   headers:{
    //     Authorization:`Bearer ${token}`
    //   }
    // }).then((res)=>{
    //   dispatch(getProductsList())
    //   handleAlert(res.data.message,"success")
    // }).catch((err)=>{
    //   handleAlert(err.response.data.message,"error")
    // })
    // setCouponedModal(false)
  }

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table product">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            <Th>Brand Name</Th>
            {/* <Th>Category</Th> */}
            {/* <Th>Price</Th> */}
            <Th>Stock</Th>
            <Th>Sold</Th>
            {/* <Th>Brand</Th> */}
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {tbody && tbody?.map((item, index) => (
            <Tr key={index}>
              <Td title={index + 1}>
                <Box className="mc-table-check">
                  <Text>#{index + 1}</Text>
                </Box>
              </Td>
              <Td>
                <Box className="mc-table-product md">
                  <Image src={`${process.env.REACT_APP_SERVER_URL}/${item.image}`} alt={"Cover"} />
                  <Box className="mc-table-group">
                    <Heading as="h6">{item.name}</Heading>
                    {/* <Text>{item.description}</Text> */}
                  </Box>
                </Box>
              </Td>

              {/* <Td>{item.category}</Td> */}
              {/* <Td>
                <Box className="mc-table-price">
                  <Text>{item.price}</Text>
                </Box>
              </Td> */}
              <Td>{item.stock}</Td>
              <Td>
              {item.sold}
              </Td>
              {/* <Td>
                <Box className="mc-table-rating">
                  <Heading>VOLVO</Heading>
                </Box>
              </Td> */}
              <Td>
                <Box className="mc-table-action">
                  {/* <Anchor
                    href={`/product-view?id=${item._id}`}
                    title="View"
                    className="material-icons view"
                  >
                    visibility
                  </Anchor> */}
                  {/* <Button
                    title="Discount"
                    className="material-icons discount"
                    onClick={() => {setCouponedModal(true);setProductId(item._id)}}
                  >
                    discount
                  </Button> */}
                  <Anchor
                    href={`/brand-edit?id=${item._id}`}
                    title="Edit"
                    className="material-icons  edit"
                  >
                    edit
                  </Anchor>
                  <Button
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => {setAlertModal(true);setProductId(item._id)}}
                  >
                    delete
                  </Button>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to delete this Brand?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => setAlertModal(false)}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={handleDeleteProduct}
            >
              yes, delete
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      {/* <Modal show={couponedModal} onHide={() => setCouponedModal(false)}>
        <Box className="mc-discount-modal">
          <Icon type="discount" />
          <Heading as="h3">Product's Coupon Status!</Heading>
          <Text as="p">Choose Status of Coupon to this product?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-primary"
              onClick={()=>handleCouponedProduct(false)}
            >
              has Coupon
            </Button>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={()=>handleCouponedProduct(true)}
            >
              has'nt Coupon
            </Button>
          </Modal.Footer>
        </Box>
      </Modal> */}
    </Box>
  );
}
