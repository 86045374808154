import { CategoryRounded, RateReviewRounded, SearchRounded } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../store/categoriesSlice";
import { getProductsCounter } from "../../store/productsCounterSlice";
import { getProductsList } from "../../store/productsListSlice";
import Pagination from "../Pagination";
import Box from "../elements/Box";
import { MuiField } from "../elements/MuiField";
import ProductsTable from "../tables/ProductsTable";
import CardHeader from "./CardHeader";
import axios from "axios";
import Cookies from 'js-cookie'

export default function ProductsCard({ title, dotsMenu, table }) {
  const [category, setCategory] = useState("")
  const [rating, setRating] = useState("")
  const [search, setSearch] = useState("")
  const { productsList } = useSelector((state) => state.productsList)
  const { categories } = useSelector((state) => state.category)
  const { token } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const [categoryy, setCategoryy] = useState([]);
  const [brand, setBrand] = useState([]);

  const handleChangeCategory = async (e) => {
    let cat = e.target.value;
    // console.log(cat);
    setCategory(cat)
    dispatch(getProductsList({ category: cat, rating, search }))
  }

  const handleChangeBrand = async (e) => {
    let rate = e.target.value;
    setRating(rate)
    dispatch(getProductsList({ category, rating: rate, search }))
  }

  const handleChangeSearch = async (e) => {
    let s = e.target.value;
    setSearch(s)
    dispatch(getProductsList({ category, rating, search: s }))
  }

  const getAllCatandBrand = async () => {
    let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/categories-brands`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setCategoryy(res.data.categories.map((cat) => {
      return cat.name
    }));
    setBrand(res.data.brands
      .map((brand) => {
        return brand.name
      }))
  }

//   let products = [];
// if (productsList) {
//     products = productsList.slice(0, 10);
// } else {
//     console.error("productsList is null");
// }
  useEffect(() => {
    getAllCatandBrand();
    dispatch(getProductsList())
    const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
    if (token) {
      dispatch(getProductsCounter())
    }
    // dispatch(getProductsCounter())
    // dispatch(getCategory())
  }, [dispatch])
  return (
    <Box className="mc-card">
      <CardHeader title={title} />
      <Row className="mb-4">
        <Col xs={12} sm={6} md={4} lg={4}>
          <MuiField
            placeholder="Category By"
            select={true}
            value={category}
            icon={<CategoryRounded />}
            onChange={handleChangeCategory}
            // data={categoryy ? Object.keys(categoryy) : []}
            data={categoryy}
          />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
          <MuiField
            placeholder="Brand By"
            select={true}
            value={rating}
            icon={<RateReviewRounded />}
            onChange={handleChangeBrand}
            // data={["1 Star" , "2 Star" , "3 Star" , ""]}
            data={brand}
          />
        </Col>
        <Col xs={12} sm={6} md={4} lg={4}>
          <MuiField
            placeholder="Search..."
            type={"search"}
            value={search}
            icon={<SearchRounded />}
            onChange={handleChangeSearch}
          />
        </Col>
      </Row>
      {/* <ProductsTable tbody={`${productsList.slice(0, 10)}`} /> */}
      {/* <ProductsTable tbody={products} /> */}
      <ProductsTable tbody={productsList} />
      {/* <Pagination /> */}
    </Box>
  );
}
