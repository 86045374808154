import { AccountBalanceRounded, CategoryRounded, DescriptionRounded, EmailRounded, PasswordRounded, PhoneRounded, PriceChangeRounded, ShopRounded, ShoppingBasketRounded, TextFieldsRounded, TitleRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
  Anchor,
  Box,
  Button,
  Icon,
  Image,
  Input,
  Label,
  Text
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/productUpload.json";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { createUserInitialValues, createUserValidationSchema } from "../../schemas/CreateUser";

export default function CreateUser() {
  const navigate = useNavigate();
  const dispatch =  useDispatch()
  const {token} = useSelector((state)=>state.auth)

  const createUserFormik = useFormik({
    initialValues: createUserInitialValues,
    validationSchema: createUserValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log('test');
      console.log(values);
      if(!values.image){
        handleAlert("Please Choose User Images","erorr")
        return
      }
      const formData = new FormData();
      
      new Array(values.image.length).fill(0).map((_,i) => {
        formData.append(`image`, values.image[i]);
        return 0;
      });
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('phone', values.phone);
      formData.append('password', values.password);
      formData.append('role', values.role);
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/users`, formData,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        })
        .then((res) => {
          // console.log(res.data);
          navigate(`${process.env.REACT_APP_USERS_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          resetForm();
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
        });
    },
  });

  useEffect(()=>{
  },[dispatch])

  return (
    <PageLayout>
      <form onSubmit={createUserFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Create User"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  User
                </li>
                <li className="mc-breadcrumb-item">
                  Create
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="basic information" />
              <Row>
                <Col xl={12}>
                  <MuiField
                    name="email"
                    label="Email"
                    icon={<EmailRounded/>}
                    type="email"
                    formik={createUserFormik}
                  />
                </Col>
                <Col xl={12}>
                  <MuiField
                    name="name"
                    label="Full Name"
                    type="text"
                    icon={<TextFieldsRounded/>}
                    formik={createUserFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="phone"
                    label="Phone Number"
                    icon={<PhoneRounded/>}
                    type="text"
                    formik={createUserFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="role"
                    label="Choose Role"
                    select={true}
                    icon={< AccountBalanceRounded/>}
                    formik={createUserFormik}
                    data={["user","admin","guest"]}
                  />
                </Col>
                <Col xl={12}>
                  <MuiField
                    name="password"
                    label="Password"
                    icon={<PasswordRounded/>}
                    type="password"
                    formik={createUserFormik}
                  />
                </Col>
              </Row>
            </CardLayout>
          </Col>
          <Col xl={6}>
            <CardLayout>
              <CardHeader
                title="User Images"
              />
              <Box className="mc-product-upload-media">
                <Box className="mc-product-upload-file">
                  <Input
                    type="file"
                    id="image"
                    multiple
                    onChange={(event) => {
                      createUserFormik.setFieldValue('image', event.currentTarget.files);
                    }}
                  />
                  <Label htmlFor="image">
                    <Icon type="collections" />
                    <Text className={`${createUserFormik.values.image && "whatsapp"}`}>{createUserFormik.values.image ? "Upload Successfully!!" : "Upload Image"}</Text>
                  </Label>
                </Box>
              </Box>
            </CardLayout>
          </Col>
          <Col xl={6}>
            {createUserFormik.values.image && (
              <CardLayout>
                <CardHeader
                  title="Chosen Image"
                />
                <Box className={`grid jcs aifs g10 images_box_2`}>
                  {
                    new Array(createUserFormik.values.image.length).fill(0).map((_, i) => {
                      return (
                        <Image key={i} src={URL.createObjectURL(createUserFormik.values.image[i])} alt={"image"} className={`image`} />
                      )
                    })
                  }
                </Box>
              </CardLayout>
            )}
          </Col>
          <Col xl={12}>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={"submit"}
            >
              Create
            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
