import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../components/Logo";
import { Box, Button, Heading, Image } from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/reset.json";
import { handleAlert } from "../../functions/handleAlert";
import {
  resetPasswordInitialValues,
  resetPasswordValidationSchema,
} from "../../schemas/ResetPassword";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { id } = useParams();

  const resetPasswordFormik = useFormik({
    initialValues: resetPasswordInitialValues,
    validationSchema: resetPasswordValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      delete values.confirmPassword;
      values.hashedUniqueString = id;
      await axios
        .put(`${process.env.REACT_APP_SERVER_URL}/ResetPassword`, values)
        .then((res) => {
          navigate(`${process.env.REACT_APP_LOGIN_ROUTE}`);
          resetForm();
          handleAlert(res.data.message, "success");
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
        });
    },
  });
  return (
    <Box className="mc-auth">
      <Image
        className="mc-auth-pattern"
        src={data?.pattern.src}
        alt={data?.pattern.alt}
      />
      <Box className="mc-auth-group center_rel_y">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <form
          className="mc-auth-form grid jcs aic g30"
          onSubmit={resetPasswordFormik.handleSubmit}
        >
          <MuiField
            name="password"
            label="Password"
            type="password"
            formik={resetPasswordFormik}
          />
          <MuiField
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            formik={resetPasswordFormik}
          />
          <Button
            className={`mc-auth-btn ${data?.button.fieldSize}`}
            type={data?.button.type}
          >
            {data?.button.text}
          </Button>
        </form>
      </Box>
    </Box>
  );
}
