import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Logout from "./components/sidebar/Logout";
import { handleAlert } from "./functions/handleAlert";
import { getAccountData, login } from "./store/authSlice";

export default function App() {

  const navigatn = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`);
      const userId = Cookies.get(`${process.env.REACT_APP_USERID_NAME}`);
      if(!token || !userId){
        if (
          !(
            pathname === `${process.env.REACT_APP_LOGIN_ROUTE}` ||
            pathname === `${process.env.REACT_APP_REGISTER_ROUTE}` ||
            pathname === `${process.env.REACT_APP_FOROGT_PASSWORD_ROUTE}` ||
            pathname === `${process.env.REACT_APP_RESET_PASSWORD_ROUTE}/${id}`
          )
        ) {
          navigatn(`${process.env.REACT_APP_LOGIN_ROUTE}`);
          dispatch(Logout());
        }
      }else if(token && userId){
        dispatch(login({token,userId}))
        dispatch(getAccountData())
      }
    } catch (err) {
      handleAlert(err.message, "erorr");
    }
  }, [navigatn, pathname, dispatch, id]);
  
  return <Outlet />;
}
