import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Item, Anchor } from "../../components/elements";
import { CardLayout, CardHeader, FloatCard } from "../../components/cards";
import { Breadcrumb, Pagination } from "../../components";
import LabelField from "../../components/fields/LabelField";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/orderList.json";
import { useDispatch, useSelector } from "react-redux";
import { getEmailsCounter } from "../../store/orderCounterSlice";
import EmailsTable from "../../components/tables/EmailsTable";
import { getEmailsSentList } from "../../store/emailsSentListSlice";

export default function MailList() {
    const dispatch =useDispatch()
    const {emailsCounter,isLoading} = useSelector((state)=>state.emailsCounter)

    useEffect(()=>{
        // dispatch(getEmailsCounter())
        dispatch(getEmailsSentList())
    },[dispatch])
  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={data?.pageTitle}>
                <Item className="mc-breadcrumb-item">
                    <Anchor className="mc-breadcrumb-link" href={"/"}>
                        Home
                    </Anchor>
                </Item>
                <Item className="mc-breadcrumb-item">
                    emails List
                </Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
        <Col xl={12}>
            <FloatCard
              variant={"lg blue"}
              digit={isLoading ? 1 : emailsCounter }
              title={"Total Emails"}
              icon={"email"}
            />
        </Col>
        <Col xl={12}>
          <CardLayout>
            <CardHeader title="Emails information" />
            <EmailsTable />
          </CardLayout>
        </Col>
      </Row>
    </PageLayout>
  );
}
