import { ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import TheRouter from "./TheRouter";
import { LoaderProvider } from "./context/Preloader";
import { BootThemeProvider } from "./context/Themes";
import { UserProvider } from "./context/User";
import { store } from "./store/store";
import { theme } from "./theme/theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BootThemeProvider>
      <LoaderProvider>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <TheRouter />
            <Toaster />
          </ThemeProvider>
        </UserProvider>
      </LoaderProvider>
    </BootThemeProvider>
  </Provider>,
);
