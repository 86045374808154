import * as yup from "yup"

export const createUserValidationSchema = yup.object({
  email: yup.string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  name: yup.string('Enter your Full Name')
    .required('Full Name is required'),
  phone: yup.string('Enter your Phone Number')
    .required('Phone Number is required'),
  password: yup.string('Enter your password')
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
  role: yup.string('Enter User Role').required('Role is required'),
})

export const createUserInitialValues = {
  email: "",
  name: "",
  phone: "",
  password: "",
  role: "",
  image: null
}