import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getProduct = createAsyncThunk("products", async (args) => {
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/${args.id}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  
  return res
})

const initialState = {
  product: null,
  reviews: null,
  isLoading: true
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getProduct.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProduct.fulfilled, (state, { payload }) => {
      state.product = payload.data
      // state.reviews = payload.reviews.reverse()
      state.isLoading = false
    })
    builder.addCase(getProduct.rejected, (state, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage, "error")
      } else {
        handleAlert(action.error.message, "error")
      }
    })
  }
})

export default productSlice.reducer