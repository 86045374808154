import { AccountCircleRounded, SecurityRounded } from "@mui/icons-material";
import { InputAdornment } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "../../context/User";
import { handleAlert } from "../../functions/handleAlert";
import { handleDate } from "../../functions/handleDate";
import { PrimaryTextField } from "../../mui/PrimaryTextField";
import { getUsersList } from "../../store/usersListSlice";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Icon,
  Image,
  Text
} from "../elements";
import { Table, Tbody, Td, Th, Thead, Tr } from "../elements/Table";
import { getUsersCounter } from "../../store/usersCounter";
const roles = ["user","admin"]
const statuses = ["verified","pending","blocked"]

export default function UsersTable({ tbody }) {
  const {token} = useSelector((state)=>state.auth)
  const [role, setRole] = useState("")
  const [status, setStatus] = useState("")
  const [userId, setUserId] = useState("")
  const {editableUserData, setEditableUserData} = useContext(UserContext)
  const [editModal, setEditModal] = React.useState(false);
  const [blockModal, setBlockModal] = React.useState(false);
  const dispatch = useDispatch()

  //Change User Role Or Role
  const handleChangeUserSubmit = async(e)=>{
    e.preventDefault()
    await axios
      .patch(`${process.env.REACT_APP_SERVER_URL}/dashboard/changeUserRoleAndStatus/${editableUserData._id}`, {role,status},{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })
      .then((res) => {
        handleAlert(res.data.message, "success");
        dispatch(getUsersList())
        dispatch(getUsersCounter())
        setEditModal(false)
      })
      .catch((err) => {
        handleAlert(err.response.data.message, "error");
      });
  }

  const handleOpenEditModal=(data)=>{
    setEditModal(true)
    setEditableUserData(data)
  }

  const handleCloseChangeModal=()=>{
    setRole("")
    setStatus("")
    setEditModal(false)
  }

  //Block User
  const deleteUser=async(e)=>{
    e.preventDefault()
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/users/${userId}`,{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })
      .then((res) => {
        handleAlert(res.data.message, "success");
        dispatch(getUsersList())
        dispatch(getUsersCounter())
        setBlockModal(false)
      })
      .catch((err) => {
        handleAlert(err.response.data.message, "error");
      });
  }

  const handleOpenBlockModal=(id)=>{
    setBlockModal(true)
    setUserId(id)
  }

  useEffect(()=>{
    if(editableUserData){
      setRole(editableUserData.role)
      setStatus(editableUserData.status)
    }
  },[editableUserData])

  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head primary">
          <Tr>
            <Th>
              <Box className="mc-table-check">
                <Text>uid</Text>
              </Box>
            </Th>
            <Th>Name</Th>
            <Th>Role</Th>
            <Th>Email</Th>
            <Th>Phone</Th>
            {/* <Th>Status</Th> */}
            <Th>Created</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody className="mc-table-body even">
          {tbody?.map((item, index) => (
            <Tr key={index}>
              <Td title="id">
                <Box className="mc-table-check">
                  <Text>#{index + 1}</Text>
                </Box>
              </Td>
              <Td title={item.name}>
                <Box className="mc-table-profile">
                  <Image src={`${process.env.REACT_APP_SERVER_URL}/${item.image}`} alt={"avatar"} />
                  <Text>{`${item.name} `}</Text>
                </Box>
              </Td>
              <Td title={item.role}>
                <Box className="mc-table-icon role">
                  {item.role === "user" && (
                    <Icon className="material-icons yellow">
                      {item.role.icon}
                    </Icon>
                  )}
                  {item.role === "member" && (
                    <Icon className="material-icons green">
                      {item.role.icon}
                    </Icon>
                  )}
                  {item.role === "admin" && (
                    <Icon className="material-icons purple">
                      {item.role.icon}
                    </Icon>
                  )}
                  {item.role === "founder" && (
                    <Icon className="material-icons blue">
                      {item.role.icon}
                    </Icon>
                  )}
                  <Text as="span">{item.role}</Text>
                </Box>
              </Td>
              <Td title={item.email}>{item.email}</Td>
              <Td title={item.phone}>{item.phone}</Td>
              {/* <Td title={item.status}>
                {item.status === "verified" && (
                  <Text className="mc-table-badge green">{item.status}</Text>
                )}
                {item.status === "pending" && (
                  <Text className="mc-table-badge purple">{item.status}</Text>
                )}
                {item.status === "blocked" && (
                  <Text className="mc-table-badge red">{item.status}</Text>
                )}
              </Td> */}
              <Td title={item.created}>{handleDate(item.createdAt)}</Td>
              <Td>
                <Box className="mc-table-action">
                  <Anchor
                    href={`/user-profile?id=${item._id}`}
                    title="View"
                    className="material-icons view"
                  >
                    visibility
                  </Anchor>
                  {/* <Button
                    title="Edit"
                    className="material-icons edit"
                    onClick={() => handleOpenEditModal(item)}
                  >
                    edit
                  </Button>*/}
                  <Button
                    title="Delete"
                    className="material-icons delete"
                    onClick={() => handleOpenBlockModal(item._id)}
                  >
                    delete
                  </Button> 
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal
        show={editModal}
        onHide={handleCloseChangeModal}
      >
        <form onSubmit={handleChangeUserSubmit}>
          <Box className="mc-user-modal">
            <Image src={editableUserData?.profileImg} alt={"avatar"} />
            <Heading as="h4">{editableUserData?.firstName}</Heading>
            <Text as="p">{editableUserData?.email}</Text>
            <Box className={`grid jcs aic g20`}>
              <PrimaryTextField
                fullWidth
                variant={"outlined"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleRounded/>
                    </InputAdornment>
                  ),
                }}
                onChange={(e)=>setRole(e.target.value)}
                value={role === "" ? editableUserData && editableUserData.role : role}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option key={-1} value={""}>
                  Role
                </option>
                {
                  roles && roles.map((d,i)=>(
                    <option key={i} value={d}>
                      {d}
                    </option>
                  ))
                }
              </PrimaryTextField>
              <PrimaryTextField
                fullWidth
                variant={"outlined"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SecurityRounded/>
                    </InputAdornment>
                  ),
                }}
                value={status === "" ? editableUserData && editableUserData.status: status}
                onChange={(e)=>setStatus(e.target.value)}
                select
                SelectProps={{
                  native: true,
                }}
              >
                <option key={-1} value={""}>
                  Status
                </option>
                {
                  statuses && statuses.map((d,i)=>(
                    <option key={i} value={d}>
                      {d}
                    </option>
                  ))
                }
              </PrimaryTextField>
            </Box>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseChangeModal}
              >
                close popup
              </Button>
              <Button
                type="submit"
                className="btn btn-success"
              >
                save Changes
              </Button>
            </Modal.Footer>
          </Box>
        </form>
      </Modal>

      <Modal show={blockModal} onHide={() => setBlockModal(false)}>
        <form onSubmit={deleteUser}>
          <Box className="mc-alert-modal">
            <Icon type="new_releases" />
            <Heading as="h3">are your sure!</Heading>
            <Text as="p">Want to delete this user's account?</Text>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={() => setBlockModal(false)}
              >
                nop, close
              </Button>
              <Button
                type="submit"
                className="btn btn-danger"
              >
                yes, delete
              </Button>
            </Modal.Footer>
          </Box>
        </form>
      </Modal>
    </Box>
  );
}
