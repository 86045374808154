import { AccountCircleRounded, EmailRounded, PasswordRounded, PhoneRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Image,
  Input,
  Label,
  Text
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/register.json";
import { handleAlert } from "../../functions/handleAlert";
import {
  registerInitialValues,
  registerValidationSchema,
} from "../../schemas/Register";

export default function Register() {
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckValue = (e) => {
    setChecked(e.target.checked);
  };

  const registerFormik = useFormik({
    initialValues: registerInitialValues,
    validationSchema: registerValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!checked) {
        handleAlert("Please Agree to the all terms & conditions");
        return;
      }
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/register`, values)
        .then((res) => {
          handleAlert(res.data.message, "success");
          navigate(`${process.env.REACT_APP_LOGIN_ROUTE}`);
          resetForm();
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
        });
    },
  });

  return (
    <Box className="mc-auth">
      <Image
        src={data?.pattern.src}
        alt={data?.pattern.alt}
        className="mc-auth-pattern"
      />
      <Box className="mc-auth-group">
        <form className="mc-auth-form" onSubmit={registerFormik.handleSubmit}>
          <Box className={`grid jsc aic g10`}>
            <Logo
              src={data?.logo.src}
              alt={data?.logo.alt}
              href={data?.logo.path}
              className="mc-auth-logo"
            />
            <Heading as="h4" className="mc-auth-title">
              {data?.title.from}
            </Heading>
          </Box>
          <Box className={`grid jcs aic g20`}>
            <Box className={`grid jcs aic g20`}>
              <MuiField
                name="firstName"
                label="First Name"
                type="text"
                icon={<AccountCircleRounded/>}
                formik={registerFormik}
              />
              <MuiField
                name="lastName"
                label="Last Name"
                type="text"
                icon={<AccountCircleRounded/>}
                formik={registerFormik}
              />
              <MuiField
                name="email"
                label="Email"
                type="email"
                icon={<EmailRounded/>}
                formik={registerFormik}
              />
              <MuiField
                name="phoneNumber"
                label="Phone Number"
                type="text"
                icon={<PhoneRounded/>}
                formik={registerFormik}
              />
              <MuiField
                name="password"
                label="Password"
                type="password"
                icon={<PasswordRounded/>}
                formik={registerFormik}
              />
              <MuiField
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                icon={<PasswordRounded/>}
                formik={registerFormik}
              />
            </Box>
            <Box className="mc-auth-checkbox">
              <Input type="checkbox" id="checkbox" onChange={handleCheckValue} />
              <Label text={data?.checkLabel} htmlFor="checkbox" />
            </Box>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={data?.button.type}
            >
              {data?.button.text}
            </Button>
          </Box>
          {/* <Box className="mc-auth-connect">
                      {data?.connect.map((item, index) => (
                          <Anchor key={ index } href={ item.path } className={ item.classes }>
                              <Icon className={ item.icon }></Icon>
                              <Text as="span">{ item.text }</Text>
                          </Anchor>
                      ))}
                  </Box> */}
          {/* <Box className="mc-auth-divide">
            <Text as="span">{data?.divide.text}</Text>
          </Box> */}
          <Box className="mc-register-navigate">
            <Text as="span">{data?.navigate.title}</Text>
            <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
