import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, FileUpload } from "../../components";
import { CardLayout, TabCard } from "../../components/cards";
import { Anchor, Box, Button, Image, Input, Item } from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { saveChangesInitialValues, saveChangesValidationSchema } from "../../schemas/SaveChange";
import { getAccountData } from "../../store/authSlice";

export default function MyAccount() {
  const dispatch = useDispatch()
  const { accountData, isLoading } = useSelector((state) => state.auth)
  const [profileImg, setProfileImg] = useState();
  const { token } = useSelector((state) => state.auth);


  const saveChangesFormik = useFormik({
    initialValues: saveChangesInitialValues,
    validationSchema: saveChangesValidationSchema,
    onSubmit: async (values) => {

      if (!values.image) {
        handleAlert("Please Choose Profile Images", "erorr")
        return
      }
      const formData = new FormData()
      formData.append("name", values.name)
      formData.append("email", values.email)
      formData.append("phone", values.phone)
      formData.append("role", values.role)
      formData.append("image", profileImg)
      // formData.append("image", values.image)
      // formData.append("status", values.status)
      // formData.append("city", values.city)
      // formData.append("country", values.country)
      // console.log(profileImg);
      await axios
        .put(`${process.env.REACT_APP_SERVER_URL}/users/${accountData._id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          console.log(res.data);
          handleAlert(res.data.message, "success");
          window.location.reload();
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
        });
    },
  });

  const handleUploadImage = (e) => {
    setProfileImg(e.target.files["0"])
  }

  const handleFileOrUrl = (src) => {

    return src instanceof File ? URL.createObjectURL(src) : src
  }


  useEffect(() => {
    if (accountData) {
      saveChangesFormik.values.name = accountData.name
      saveChangesFormik.values.email = accountData.email
      saveChangesFormik.values.phone = accountData.phone
      saveChangesFormik.values.role = accountData.role
      // saveChangesFormik.values.image = `${process.env.REACT_APP_SERVER_URL}/${accountData.image}`
      saveChangesFormik.values.image = accountData.image
      setProfileImg(accountData.image)
    }
  }, [accountData])

  return (
    <PageLayout>
      <form onSubmit={saveChangesFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <Breadcrumb title={"My Account"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/user-list"}>
                  user-list
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">
                My Account
              </Item>
            </Breadcrumb>
          </Col>
          <Col xl={12}>
            {!isLoading && (
              <CardLayout>
                <Tabs defaultActiveKey="profile" id="mc" className="mc-tabs">
                  <Tab
                    eventKey="profile"
                    title="edit Profile"
                    className="mc-tabpane profile"
                  >
                    <TabCard title="public information">
                      <Row>
                        <Col xl={4}>
                          <Box className="mc-user-avatar-upload">
                            <Box className="mc-user-avatar">
                              <Image
                                src={`${process.env.REACT_APP_SERVER_URL}/${profileImg instanceof File ? URL.createObjectURL(profileImg) : profileImg}`}
                                alt={"avatar"}
                              />
                              {/* <Image
                                src={`${handleFileOrUrl(saveChangesFormik.values.image)}`}
                                alt={"avatar"}
                              /> */}
                            </Box>
                            {/* <Input
                              type="file"
                              id="images"
                              multiple
                              onChange={(event) => {
                                saveChangesFormik.setFieldValue('image', event.currentTarget.files);
                              }}
                            /> */}
                            <FileUpload onChange={handleUploadImage} icon="cloud_upload" text="upload" />
                          </Box>
                        </Col>
                        <Col xl={8}>
                          <Row>
                            <Col xl={6}>
                              <MuiField
                                label={"Full Name"}
                                value={accountData?.name}
                                id={"name"}
                                name={"name"}
                                formik={saveChangesFormik}
                              />
                            </Col>
                            {/* <Col xl={6}>
                              <MuiField
                                label={"Last Name"}
                                value={accountData?.lastName}
                                id={"lastName"}
                                name={"lastName"}
                                formik={saveChangesFormik}
                              />
                            </Col> */}
                            <Col xl={12}>
                              <MuiField
                                label={"Email"}
                                value={accountData?.email}
                                id={"email"}
                                name={"email"}
                                formik={saveChangesFormik}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </TabCard>
                    <TabCard title="private information">
                      <Row>
                        <Col xl={6}>
                          <MuiField
                            label={"Role"}
                            value={accountData?.role}
                            select={true}
                            data={["user", "admin", "guest"]}
                            id={"role"}
                            name={"role"}
                            formik={saveChangesFormik}
                          />
                        </Col>
                        {/* <Col xl={4}>
                          <MuiField
                            label={"Status"}
                            value={accountData?.status}
                            select={true}
                            data={["verified", "pending", "blocked"]}
                            id={"status"}
                            name={"status"}
                            formik={saveChangesFormik}
                          />
                        </Col> */}
                        <Col xl={6}>
                          <MuiField
                            label={"Phone"}
                            value={accountData?.phone}
                            id={"phone"}
                            name={"phone"}
                            formik={saveChangesFormik}
                          />
                        </Col>
                      </Row>
                    </TabCard>
                    <Button
                      className="mc-btn primary"
                      icon="verified"
                      text="Update "
                      type={"submit"}

                    ></Button>

                  </Tab>
                  {/* <Tab
                    eventKey="password"
                    title="Change Password"
                    className="mc-tabpane password"
                  >
                    <TabCard title="generate password">
                      <Row>
                        <Col xs={12} md={12}>
                          <IconField
                            icon="lock"
                            type="password"
                            placeholder="current password"
                            classes="w-100 h-lg"
                            passwordVisible
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <IconField
                            icon="add_moderator"
                            type="password"
                            placeholder="new password"
                            classes="w-100 h-lg"
                            passwordVisible
                          />
                        </Col>
                        <Col xs={12} md={6}>
                          <IconField
                            icon="verified_user"
                            type="password"
                            placeholder="confirm password"
                            classes="w-100 h-lg"
                            passwordVisible
                          />
                        </Col>
                      </Row>
                    </TabCard>
                    <Button
                      className="mc-btn primary"
                      icon="verified"
                      text="save changes"
                    />
                  </Tab> */}
                </Tabs>
              </CardLayout>
            )}
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
