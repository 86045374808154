import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Breadcrumb,
  DivideTitle,
  DuelText,
  RoundAvatar,
} from "../../components";
import {
  CardHeader,
  CardLayout,
  FloatCard
} from "../../components/cards";
import { Anchor, Box, Icon, Item, List, Text } from "../../components/elements";
import PageLayout from "../../layouts/PageLayout";
import { getUserData } from "../../store/userSlice";

export default function UserProfile() {
  const dispatch = useDispatch()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const { userData } = useSelector((state) => state.user)
  useEffect(() => {
    dispatch(getUserData({ id }))
  }, [dispatch, id])

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <CardLayout>
            <Breadcrumb title={"user profile"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  Home
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/user-list"}>
                  user-list
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">
                User-Prodile
              </Item>
            </Breadcrumb>
          </CardLayout>
        </Col>
        {userData && (
          <>
            {/* <Col xl={12}>
              <Row>
                <Col sm={12} md={6} lg={6}>
                  <FloatCard
                    variant={"sm purple"}
                    // digit={userData.orders.length}
                    digit={10}
                    title={"Total Orders"}
                    icon={"shopping_cart"}
                  />
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <FloatCard
                    variant={"sm green"}
                    // digit={userData.blogs.length}
                    digit={10}
                    title={"Total Blogs"}
                    icon={"blog"}
                  />
                </Col>
              </Row>
            </Col> */}
            <Col xl={12}>
              <CardLayout>
                <CardHeader title="user information" />
                <Box className="mc-user-group">
                  <Box className="mc-user-profile">
                    <RoundAvatar
                      src={`${process.env.REACT_APP_SERVER_URL}/${userData?.image}`}
                      alt={"avatar"}
                      size={"lg"}
                    />
                    <DuelText
                      title={`${userData.name}`}
                      // title={`${userData.firstName} ${userData.lastName}`}
                      size={"lg"}
                    />
                  </Box>
                  <Box className="mb-4">
                    <DivideTitle title="communication" className="mb-4" />
                    <List className="mc-user-metalist">
                      <Item>
                        <Icon>phone_in_talk</Icon>
                        <Text as="span">{userData.phone}</Text>
                      </Item>
                      <Item>
                        <Icon>feed</Icon>
                        <Text as="span">{userData.email}</Text>
                      </Item>
                      {
                        userData.city && userData.country && <Item>
                          <Icon>map</Icon>
                          <Text as="span">{`${userData.city} - ${userData.country}`}</Text>
                        </Item>
                      }
                    </List>
                  </Box>
                  {userData.about && <Box className="mb-4">
                    <DivideTitle title={"biography"} className="mb-3" />
                    <Text className="mc-user-bio mb-4">{userData.about}</Text>
                  </Box>}
                </Box>
              </CardLayout>
            </Col>
          </>
        )}
      </Row>
    </PageLayout>
  );
}
