import React from "react";
import { Anchor, Box, Image, Text } from "../elements";
import { Table, Tbody, Td, Th, Thead, Tr } from "../elements/Table";

export default function ClientsTable({ tbody }) {
  return (
    <Box className="mc-table-responsive">
      <Table className="mc-table">
        <Thead className="mc-table-head">
          <Tr>
            <Th>Client</Th>
            <Th>Orders</Th>
            <Th>Amount</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody className="mc-table-body">
          {tbody.map((client, index) => (
            <Tr key={index}>
              <Td>
                <Box className="mc-table-profile">
                  <Image src={client.profileImg} alt={"avatar"} />
                  <Text>{`${client.firstName} ${client.lastName}`}</Text>
                </Box>
              </Td>
              <Td>{client.orders.length}</Td>
              <Td>{`$${client.totalSpends}`}</Td>
              <Td>
                <Box className="mc-table-action">
                  <Anchor
                    href={`/user-profile?id=${client._id}`}
                    title="View"
                    className="material-icons view"
                  >
                    visibility
                  </Anchor>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
