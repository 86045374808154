import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getPopularClientsList=createAsyncThunk("popularClientsList/getPopularClientsList",async()=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listPopularClients`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return res.data.data
})

const initialState = {
  popularClientsList:0,
  isLoading:true
}

export const popularClientsListSlice = createSlice({
  name: 'popularClientsList',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getPopularClientsList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getPopularClientsList.fulfilled, (state, { payload }) => {
      state.popularClientsList = payload
      state.isLoading = false
    })
    builder.addCase(getPopularClientsList.rejected, (_, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})

export default popularClientsListSlice.reducer