import * as yup from "yup"

export const forgotPasswordValidationSchema = yup.object({
  email:yup.string('Enter your email')
  .email('Enter a valid email')
  .required('Email is required'),
})

export const forgotPasswordInitialValues={
  email:"",
}