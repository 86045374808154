import { DescriptionRounded, LinkRounded, PriceChangeRounded, TitleRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
  Anchor,
  Box,
  Button,
  Icon,
  Image,
  Input,
  Label,
  Text
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/productUpload.json";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { editBlogInitialValues, editBlogValidationSchema } from "../../schemas/EditBlog";
import { getBlogData } from "../../store/blogSlice";
import { getBlogsList } from "../../store/blogsListSlice";

export default function BlogEdit() {
  const navigate = useNavigate();
  const {token} = useSelector((state)=>state.auth)
  const {blogData} = useSelector((state)=>state.blog)
  const [tag, setTag]= useState("")
  const [tags, setTags]= useState([])
  const [url, setURL]= useState("")
  const [urls, setURLs]= useState([])
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const dispatch = useDispatch()

  const editBlogFormik = useFormik({
    initialValues: editBlogInitialValues,
    validationSchema: editBlogValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      if(values.images[0] instanceof File){
        new Array(values.images.length).fill(0).map((_,i) => {
          formData.append(`images`, values.images[i]);
          return 0;
        });
      }
      urls.map((url) => {
        formData.append(`urls`, url);
        return 0;
      });
      tags.map((tag) => {
        formData.append(`tags`, tag);
        return 0;
      });
      formData.append('title', values.title);
      formData.append('content', values.content);
      await axios
        .put(`${process.env.REACT_APP_SERVER_URL}/dashboard/editBlog/${id}`, formData,{
          headers:{
            Authorization:`Bearer ${token}`
          }
        })
        .then((res) => {
          navigate(`${process.env.REACT_APP_BLOGS_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          dispatch(getBlogsList)
          resetForm();
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
        });
    },
  });

  const handleAddTag=()=>{
    if(tag){
      setTags(()=>[...tags,tag])
    }else{
      handleAlert("Write a Tag First!!","error")
    }
    setTag("")
  }

  const handleRemoveTag=(index)=>{
    setTags(tags.slice(0,index).concat(tags.slice(index+1)))
  }

  const handleAddURL=()=>{
    if(url){
      setURLs(()=>[...urls,url])
    }else{
      handleAlert("Write a URL First!!","error")
    }
    setURL("")
  }
  
  const handleRemoveURL=(index)=>{
    setURLs(urls.slice(0,index).concat(urls.slice(index+1)))
  }

  const handleFileOrURL =(img)=>{
    return img instanceof File ? URL.createObjectURL(img) : img
  }

  useEffect(()=>{
    dispatch(getBlogData({id}))
  },[dispatch,id])

  useEffect(()=>{
    if(blogData){
      setURLs(blogData.urls)
      setTags(blogData.tags)
      editBlogFormik.values.title = blogData.title
      editBlogFormik.values.content = blogData.content
      editBlogFormik.values.images = blogData.images
    }
  },[blogData])

  return (
    <PageLayout>
      <form onSubmit={editBlogFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Blog Upload"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  Blog
                </li>
                <li className="mc-breadcrumb-item">
                  Upload
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="basic information" />
              <Row>
                <Col xl={12}>
                  <MuiField
                    name="title"
                    label="title"
                    icon={<TitleRounded/>}
                    type="text"
                    formik={editBlogFormik}
                  />
                </Col>
                <Col xl={12}>
                  <MuiField
                    name="content"
                    label="content"
                    type="text"
                    icon={<DescriptionRounded/>}
                    rows={4}
                    formik={editBlogFormik}
                  />
                </Col>
                <Col xl={6} className={`grid jcs aic g20`}>
                  <Box className=" flex jcs aic g10 mc-table-action">
                    <MuiField
                      name="tags"
                      label="Tag"
                      icon={<LinkRounded/>}
                      type="text"
                      value={tag} 
                      onChange={(e)=>setTag(e.target.value)}
                    />
                    <Button
                      title="Add"
                      className="material-icons edit"
                      onClick={handleAddTag}
                    >
                      add
                    </Button>
                  </Box>
                
                  <Box className={`flex jcfs g20 aic flex_wrap`}>
                    {
                      tags.map((tag,i)=>(
                        <Box key={i}>
                          <Text className="mc-table-badge blue">
                            <Box className={`flex jcc aic g20 pad5 mc-table-action`}> 
                              {tag}
                              <Button
                                title="Delete"
                                className="material-icons delete"
                                onClick={()=>handleRemoveTag(i)}
                              >
                                delete
                              </Button>
                            </Box>
                          </Text>
                        </Box>
                      ))
                    }
                  </Box>
                </Col>
                <Col xl={6} className={`grid jcs aic g20`}>
                  <Box className=" flex jcs aic g10 mc-table-action">
                    <MuiField
                      name="url"
                      label="URLs"
                      icon={<PriceChangeRounded/>}
                      type="text"
                      value={url} 
                      onChange={(e)=>setURL(e.target.value)}
                    />
                    <Button
                      title="Add"
                      className="material-icons edit"
                      onClick={handleAddURL}
                    >
                      add
                    </Button>
                  </Box>
                  <Box className={`flex jcfs g20 aic flex_wrap`}>
                    {
                      urls.map((url,i)=>(
                        <Box key={i}>
                          <Link to={url}>
                            <Text className="mc-table-badge purple">
                              <Box className={`flex jcc aic g20 pad5 mc-table-action`}> 
                                {url}
                                <Button
                                  title="Delete"
                                  className="material-icons delete"
                                  onClick={()=>handleRemoveURL(i)}
                                >
                                  delete
                                </Button>
                              </Box>
                            </Text>
                          </Link>
                        </Box>
                      ))
                    }
                  </Box>
                </Col>
              </Row>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader
                title="Blog Images"
              />
              <Box className="mc-product-upload-media">
                <Box className="mc-product-upload-file">
                  <Input
                    type="file"
                    id="images"
                    multiple
                    onChange={(event)=>{
                      editBlogFormik.setFieldValue('images', event.currentTarget.files);
                    }}
                  />
                  <Label htmlFor="images">
                    <Icon type="collections" />
                    <Text  className={`${editBlogFormik.values.images && "whatsapp"}`}>{editBlogFormik.values.images ? "Upload Successfully!!" :"Upload Images"}</Text>
                  </Label>
                </Box>
              </Box>
            </CardLayout>
          </Col>
          <Col xl={6}>
            {editBlogFormik.values.images && (
              <CardLayout>
                <CardHeader
                  title="Chosen Images"
                />
                {
                  new Array(editBlogFormik.values.images.length).fill(0).map((_,i)=>{
                    return (
                      <Image key={i} src={handleFileOrURL(editBlogFormik.values.images[i])} alt={"images"} className={`wid100`}/>
                    )
                  })
                }
              </CardLayout>
            )}
          </Col>
          <Col xl={12}>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={"submit"}
            >
              Edit Blog
            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
