import { CategoryRounded, DescriptionRounded, PriceChangeRounded, ShopRounded, ShoppingBasketRounded, TitleRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
  Anchor,
  Box,
  Button,
  Icon,
  Image,
  Input,
  Label,
  Text
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/productUpload.json";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { addProductInitialValues, addProductValidationSchema } from "../../schemas/AddProduct";
export default function ProductUpload() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);

  const addProductFormik = useFormik({
    initialValues: addProductInitialValues,
    validationSchema: addProductValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (!values.images) {
        handleAlert("Please Choose Product Images", "erorr")
        return
      }
      const formData = new FormData();

      new Array(values.images.length).fill(0).map((_, i) => {
        formData.append(`images`, values.images[i]);
        return 0;
      });

      // formData.append('name', values.name);
      formData.append('year', values.year);
      formData.append('hours', values.hours);
      formData.append('model', values.model);
      formData.append('serial', values.serial);
      formData.append('price', values.price);
      formData.append('location', values.location);
      formData.append('brand', values.brand);
      formData.append('category', values.category);
      formData.append('description', values.description);
      // formData.append('price', values.price);
      // formData.append('availableStock', values.availableStock);
      // formData.append('category', values.category);
      // formData.append('imageCover', values.imageCover);
      // formData.append('discountPercentage', values.discountPercentage);
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/products`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          navigate(`${process.env.REACT_APP_PRODUCTS_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
          setIsLoading(true);
        });
    },

  });

  const getAllCatandBrand = async () => {
    let res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/categories-brands`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    setCategory(res.data.categories.map((cat) => {
      return cat.name
    }));
    setBrand(res.data.brands
      .map((brand) => {
        return brand.name
      }))
  }
  useEffect(() => {
    // dispatch(getCategory());
    getAllCatandBrand();
  }, [dispatch])

  return (
    <PageLayout>
      <form onSubmit={addProductFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Product Upload"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  Product
                </li>
                <li className="mc-breadcrumb-item">
                  Upload
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="basic information" />
              <Row>

                <Col xl={6}>
                  <MuiField
                    name="year"
                    label="Year"
                    icon={<TitleRounded />}
                    type="number"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="hours"
                    label="Hours"
                    icon={<TitleRounded />}
                    type="number"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={4}>
                  <MuiField
                    name="model"
                    label="Model"
                    icon={<TitleRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={4}>
                  <MuiField
                    name="serial"
                    label="Serial"
                    icon={<TitleRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={4}>
                  <MuiField
                    name="price"
                    label="Price"
                    icon={<PriceChangeRounded />}
                    type="number"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={12}>
                  <MuiField
                    name="location"
                    label="Location"
                    icon={<TitleRounded />}
                    type="text"
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={12}>
                  <MuiField
                    name="description"
                    label="Description"
                    type="text"
                    icon={<DescriptionRounded />}
                    rows={3}
                    formik={addProductFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="category"
                    label="Add Category"
                    type="text"
                    select={true}
                    icon={<CategoryRounded />}
                    formik={addProductFormik}
                    data={category}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="brand"
                    label="Add Brand"
                    type="text"
                    select={true}
                    icon={<CategoryRounded />}
                    formik={addProductFormik}
                    data={brand}
                  />
                </Col>
              </Row>
            </CardLayout>
          </Col>
          <Col xl={6}>
            <CardLayout>
              <CardHeader
                title="Product Images"
              />
              <p>Recommended Size: 720px * 520px  </p>
              <Box className="mc-product-upload-media">
                <Box className="mc-product-upload-file">
                  <Input
                    type="file"
                    id="images"
                    multiple
                    onChange={(event) => {
                      addProductFormik.setFieldValue('images', event.currentTarget.files);
                    }}
                  />
                  <Label htmlFor="images">
                    <Icon type="collections" />
                    <Text className={`${addProductFormik.values.images && "whatsapp"}`}>{addProductFormik.values.images ? "Upload Successfully!!" : "Upload Images"}</Text>
                  </Label>
                </Box>
              </Box>
            </CardLayout>
          </Col>
          {/* <Col xl={6}>
            {addProductFormik.values.images && (
              <CardLayout>
                <CardHeader
                  title="Chosen Images"
                />
                <Box className={`grid jcs aifs g10 images_box_2`}>
                  {
                    new Array(addProductFormik.values.images.length).fill(0).map((_, i) => {
                      return (
                        <Image key={i} src={URL.createObjectURL(addProductFormik.values.images[i])} alt={"images"} className={`image`} />
                      )
                    })
                  }
                </Box>
              </CardLayout>
            )}
          </Col> */}
          <Col xl={6}>
            {addProductFormik.values.images && (
              <CardLayout>
                <CardHeader title="Chosen Images" />
                <Box className={`grid jcs aifs g10 images_box_2`}>
                  {Array.from(addProductFormik.values.images).map((image, i) => {
                    if (image instanceof Blob || image instanceof File) {
                      try {
                        const objectURL = URL.createObjectURL(image);
                        return (
                          <Image key={i} src={objectURL} alt={"images"} className={`image`} />
                        );
                      } catch (error) {
                        console.error('Failed to create object URL for image:', error);
                        return null;
                      }
                    } else {
                      console.error('Invalid image type:', image);
                      return null;
                    }
                  })}
                </Box>
              </CardLayout>
            )}
          </Col>

          <Col xl={12}>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={"submit"}
            >
              {isLoading ? <Spinner animation="grow" /> : "Add Product"}
            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
