import React from "react";
import { useDispatch } from "react-redux";
import { handleAlert } from "../../functions/handleAlert";
import { logout } from "../../store/authSlice";
import { Anchor, Box } from "../elements";

export default function Logout({ data }) {
  const dispacth = useDispatch();

  const handleLogout = () => {
    dispacth(logout());
    handleAlert("Logout Successfully!!", "success");
  };
  return (
    <Box className="mc-sidebar-logout text-center">
      <Anchor
        href={data?.path}
        icon={data?.icon}
        text={data?.text}
        className="mc-btn primary sm"
        onClick={handleLogout}
      />
    </Box>
  );
}
