import axios from 'axios'
import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { handleAlert } from '../functions/handleAlert'
import { handleDate } from '../functions/handleDate'
import { getBlogsList } from '../store/blogsListSlice'
import DivideTitle from './DivideTitle'
import DuelText from './DuelText'
import RoundAvatar from './RoundAvatar'
import { Anchor, Button, Heading, Icon, Image, Text } from './elements'
import Box from './elements/Box'

export const Blog = ({blog}) => {
  const [deleteModal , setDeleteModal] = useState(false)
  const dispatch = useDispatch()
  const {token} = useSelector((state)=>state.auth)

  //Delete Blog
  const handleDeleteBlogSubmit = async(e)=>{
    e.preventDefault()
    await axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/dashboard/deleteBlog/${blog._id}`,{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })
      .then((res) => {
        handleAlert(res.data.message, "success");
        dispatch(getBlogsList())
        setDeleteModal(false)
      })
      .catch((err) => {
        handleAlert(err.response.data.message, "error");
      });
  }
  return (
    <Box className={`grid jcs aic g10`}>
      <Box className="mc-user-profile">
        <RoundAvatar
          src={blog.author.profileImg}
          alt={"avatar"}
          size={"sm"}
        />
        <DuelText
          title={`${blog.author.firstName} ${blog.author.lastName}`}
          size={"sm"}
          descrip={handleDate(blog.createdAt)}
        />   
      </Box>
      <Heading as="h2" className="mc-product-view-info-title">
        {blog?.title}
      </Heading>
      <Box className="mc-product-view-descrip">
        <Text>{blog?.content}</Text>
      </Box>
      <Row>
        {blog.tags.length > 0 && <Col xl={6}>
          <DivideTitle title={`Tags (${blog.tags.length})`} className="mb-4" />
          <Box className={`flex g20 jcfs aic flex_wrap`}>
            {blog.tags.map((tag,i)=>(
              <Text className="mc-table-badge blue" key={i}>
                <Box className={`flex jcc aic g20 pad5 mc-table-action`}> 
                  {tag}
                </Box>
              </Text>
            ))}
          </Box>
        </Col>}
        {blog.urls.length > 0 && <Col xl={6}>
          <DivideTitle title={`URLs (${blog.urls.length})`} className="mb-4" />
          <Box className={`flex g20 jcfs aic flex_wrap`}>
            {blog.urls.map((url,i)=>(
              <Link to={url}>
                <Text className="mc-table-badge purple" key={i}>
                  <Box className={`flex jcc aic g20 pad5 mc-table-action`}> 
                    {url}
                  </Box>
                </Text>
              </Link>
            ))}
          </Box>
        </Col>}
        {blog.images.length > 0 && <Col xl={12}>
          <DivideTitle title={`Images (${blog.images.length})`} className="mb-4" />
          <Box className={`grid g30 jcfs aifs images_box_4`}>
            {blog.images.map((img,i)=>(
              <Image src={img} key={i} className={`image`} alt={"blog"}/>
            ))}
          </Box>
        </Col>}
        {/* {
          blog.images.length > 0 && (
            <Box className="mc-product-view-gallery">
              {blog?.images.map((item, index) => (
                <Image key={index} src={item} alt={"blog"} />
              ))}
            </Box>
          )
        } */}
      </Row>
      <Box className="mc-table-action flex jcfe aic pad20">
        <Anchor
          title="Edit"
          className="material-icons edit"
          href={`/blog-edit?id=${blog._id}`}
        >
          edit
        </Anchor>
        <Button
          title="Delete"
          className="material-icons delete"
          onClick={()=>setDeleteModal(true)}
        >
          delete
        </Button>
      </Box>
      <Modal show={deleteModal} onHide={() => setDeleteModal(false)}>
        <form onSubmit={handleDeleteBlogSubmit}>
          <Box className="mc-alert-modal">
            <Icon type="delete" />
            <Heading as="h3">are your sure!</Heading>
            <Text as="p">Want to Delete This Blog?</Text>
            <Modal.Footer>
              <Button
                type="button"
                className="btn btn-secondary"
                onClick={() => setDeleteModal(false)}
              >
                nop, close
              </Button>
              <Button
                type="submit"
                className="btn btn-danger"
              >
                yes, delete
              </Button>
            </Modal.Footer>
          </Box>
        </form>
      </Modal>
    </Box>
  )
}
