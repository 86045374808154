import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getBlogData = createAsyncThunk("blog/getBlogData",async(args)=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/displayBlog/${args.id}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return res.data.data
})

const initialState = {
  blogData:null,
  isLoading:true
}

export const blogSlice = createSlice({
  name: 'blog',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getBlogData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getBlogData.fulfilled, (state, { payload }) => {
      state.blogData = payload
      state.isLoading = false
    })
    builder.addCase(getBlogData.rejected, (state, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})


export default blogSlice.reducer