import { EmailRounded, PasswordRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import {
  Anchor,
  Box,
  Button,
  Heading,
  Image,
  Text,
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/login.json";
import { handleAlert } from "../../functions/handleAlert";
import { loginInitialValues, loginValidationSchema } from "../../schemas/Login";
import { login } from "../../store/authSlice";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loginFormik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      // console.log(values);
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/login`, values)
        // .post(`http://localhost:3000/login`, values)
        .then((res) => {
          // console.log(res.data);
          // navigate(`http://localhost:4500/`);
          navigate(`${process.env.REACT_APP_ECOMMERCE_ROUTE}`);
          dispatch(
            login({
              token: res.data.token,
              userId: res.data.userId,
            }),
          );
          resetForm();
          handleAlert(res.data.message, "success");
        })
        .catch((err) => {
          handleAlert(err.response.data.message, "error");
        });
    },
  });
  return (
    <Box className="mc-auth">
      <Image
        src={data?.pattern.src}
        alt={data?.pattern.alt}
        className="mc-auth-pattern"
      />
      <Box className="mc-auth-group center_rel_y">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          // href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <form
          className="mc-auth-form grid jcs aic g30"
          onSubmit={loginFormik.handleSubmit}
        >
          <Box className={`grid jcs aic g20`}>
            <MuiField
              name="email"
              label="Email"
              type="email"
              formik={loginFormik}
              icon={<EmailRounded/>}
            />
            <MuiField
              name="password"
              label="Password"
              type="password"
              icon={<PasswordRounded/>}
              formik={loginFormik}
            />
          </Box>
          <Box className={`grid jcs aic g10`}>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={data?.button.type}
            >
              {data?.button.text}
            </Button>
            {/* <Anchor className="mc-auth-forgot" href={data?.forgot.path}>
              {data?.forgot.text}
            </Anchor> */}
          </Box>
        </form>
        {/* <Box className="mc-auth-navigate">
          <Text as="span">{data?.navigate.title}</Text>
          <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
        </Box> */}
      </Box>
    </Box>
  );
}
