import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getRecentActivitiesList=createAsyncThunk("recentActivitiesList/getRecentActivitiesList",async()=>{
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`) 
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listRecentActivities`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return res.data.data
})

const initialState = {
  recentActivitiesList:0,
  isLoading:true
}

export const recentActivitiesListSlice = createSlice({
  name: 'recentActivitiesList',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getRecentActivitiesList.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getRecentActivitiesList.fulfilled, (state, { payload }) => {
      state.recentActivitiesList = payload
      state.isLoading = false
    })
    builder.addCase(getRecentActivitiesList.rejected, (_, action) => {
      if (action.payload) {
        handleAlert(action.payload.errorMessage , "error") 
      } else {
        handleAlert(action.error.message, "error") 
      }
    })
  }
})

export default recentActivitiesListSlice.reducer