import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import Cookies from 'js-cookie'
import { handleAlert } from '../functions/handleAlert'

export const getCategoriesList = createAsyncThunk("categoriesList/getCategoriesList", async (args) => {
    const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
    let res;
    if (args) {
        const { category, search, brand } = args
        res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/category?limit=30&page=0&search=${search || ""}&category=${category || ""}&brand=${brand || ""}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        // res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/category?limit=10&page=0&search=`, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // })
        // res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listProducts?limit=10&page=0&search=${search || ""}&category=${category || ""}&rating=${rating || ""}`, {
        //     headers: {
        //         Authorization: `Bearer ${token}`
        //     }
        // })
    } else {
        // res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/dashboard/listProducts?limit=10&page=0&search=&category=&rating=`,{
        res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/category?limit=30&page=0&search=`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
    }
    // console.log(res.data);
    return res.data
})

const initialState = {
    categoriesList: null,
    isLoading: true
}

export const categoriesListSlice = createSlice({
    name: 'categoriesList',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getCategoriesList.pending, (state, { payload }) => {
            state.isLoading = true
        })
        builder.addCase(getCategoriesList.fulfilled, (state, { payload }) => {
            state.categoriesList = payload
            state.isLoading = false
        })
        builder.addCase(getCategoriesList.rejected, (_, action) => {
            if (action.payload) {
                handleAlert(action.payload.errorMessage, "error")
            } else {
                handleAlert(action.error.message, "error")
            }
        })
    }
})


export default categoriesListSlice.reducer