import axios from "axios";
import { useFormik } from "formik";
import { Col, Row, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
  Anchor,
  Box,
  Button,
  Icon,
  Image,
  Input,
  Label,
  Text,
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getCouponData } from "../../store/brandSlice";
import { editCategoryInitialValues, editCategoryValidationSchema } from "../../schemas/EditCategory";
import { getCategory } from "../../store/categoriesSlice";
import { getCategoriesList } from "../../store/categoriesListSlice";
import { object } from "yup";

export default function CategoryEdit() {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [bool, setBool] = useState(true)
  const [srcImage, setSrcImage] = useState([])
  // const { couponData } = useSelector((state) => state.coupon);
  const { token } = useSelector((state) => state.auth);
  const { category } = useSelector((state) => state.category);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  const addCategoryFormik = useFormik({
    initialValues: editCategoryInitialValues,
    validationSchema: editCategoryValidationSchema,

    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (!values.images) {
        handleAlert("Please Choose Category Images", "erorr")
        return
      }

      const formData = new FormData();
      if (values.images[0] instanceof File) {
        new Array(values.images.length).fill(0).map((_, i) => {
          formData.append(`image`, values.images[i]);
          return 0;
        });
      }
      formData.append('name', values.name);

      await axios
        .put(`${process.env.REACT_APP_SERVER_URL}/category/${id}`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          // console.log("hey there");
          navigate(`${process.env.REACT_APP_CATEGORIES_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          resetForm();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(true);
          handleAlert(err.response.data.message, "error");
        });
    },
  });

  const handleFileOrUrl = (src) => {
    
    return src instanceof File ? URL.createObjectURL(src) : src
  }

 

  useEffect(() => {
    // dispatch(getCategoriesList({ id }))
    dispatch(getCategory({ id }))
    // console.log(id);

  }, [dispatch, id])

  useEffect(() => {
    if (category) {
      setBool(!bool)

      addCategoryFormik.values.name = category && category?.name
      // setSrcImage([{path:`${process.env.REACT_APP_SERVER_URL}/${category?.image}`}])
      // console.log(srcImage);
      // addCategoryFormik.values.images = category && srcImage

      // addCategoryFormik.values.images = Object.keys(category?.image).map((e) => {
      //   return `${e.toString()}`
      // })
      addCategoryFormik.values.images = category && `${process.env.REACT_APP_SERVER_URL}/${category?.image}`
    }
  }, [category])

  // useEffect(() => {
  //   if (couponData) {
  //     setBool(!bool)
  //     addCategoryFormik.values.code = couponData.code
  //     addCategoryFormik.values.discountPercentage = couponData.discountPercentage
  //     addCategoryFormik.values.expirationDate = new Date(couponData.expirationDate).toISOString().split('T')[0]
  //     addCategoryFormik.values.usageLimit = couponData.usageLimit
  //   }
  // }, [couponData]);

  return (
    <PageLayout>
      <form onSubmit={addCategoryFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Category Edit"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  Category
                </li>
                <li className="mc-breadcrumb-item">
                  Edit
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="basic information" />
              <Row>
                <Col xl={12}>
                  <MuiField
                    name="name"
                    id="name"
                    label="Category Name"
                    type="text"
                    formik={addCategoryFormik}
                  />
                </Col>
                {/* <Col xl={6}>
                  <MuiField
                    name="discountPercentage"
                    id="discountPercentage"
                    label="Discount Percentage"
                    type="number"
                    formik={addCategoryFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    id="usageLimit"
                    name="usageLimit"
                    label="Usage Limit"
                    type="number"
                    formik={addCategoryFormik}
                  />
                </Col>
                <Col xl={12}>
                  <Text>Expiration Date</Text>
                  <MuiField
                    id="expirationDate"
                    name="expirationDate"
                    type="date"
                    formik={addCategoryFormik}
                  />
                </Col> */}
                <Col xl={6}>
                  <CardLayout>
                    <CardHeader
                      title="Category Images"
                    />
                    <Box className="mc-product-upload-media">
                      <Box className="mc-product-upload-file">
                        <Input
                          type="file"
                          id="images"
                          multiple
                          onChange={(event) => {
                            addCategoryFormik.setFieldValue('images', event.currentTarget.files);
                          }}
                        />
                        <Label htmlFor="images">
                          <Icon type="collections" />
                          <Text className={`${addCategoryFormik.values.images && "whatsapp"}`}>{addCategoryFormik.values.images ? "Upload Successfully!!" : "Upload Images"}</Text>
                        </Label>
                      </Box>
                    </Box>
                  </CardLayout>
                </Col>
                <Col xl={6}>
                  {addCategoryFormik.values.images && (
                    <CardLayout>
                      <CardHeader
                        title="Chosen Images"
                      />
                      <Box className={`grid jcs aifs g10 images_box_2`}>
                        {
                          // new Array(addCategoryFormik.values.images.length).fill(0).map((_, i) => {
                          //   return (
                          //     <Image key={i} src={`${handleFileOrUrl(addCategoryFormik.values.images[i])}`} alt={"images"} className={`image`} />
                          //   )
                          // })
                          <Image src={`${handleFileOrUrl(addCategoryFormik.values.images)}`} alt={"images"} className={`image`}  />
                        }
                      </Box>
                    </CardLayout>
                  )}
                </Col>
              </Row>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <Button
              className={`mc-auth-btn h-sm`}
              type={"submit"}
            >
              {isLoading ? <Spinner animation="grow" /> : "Edit Category"}
              
            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
