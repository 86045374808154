import { CategoryRounded, TitleRounded } from "@mui/icons-material";
import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb";
import { CardHeader, CardLayout } from "../../components/cards";
import {
  Anchor,
  Box,
  Button,
  Icon,
  Image,
  Input,
  Label,
  Text
} from "../../components/elements";
import { MuiField } from "../../components/elements/MuiField";
import data from "../../data/master/productUpload.json";
import { handleAlert } from "../../functions/handleAlert";
import PageLayout from "../../layouts/PageLayout";
import { addCategoryInitialValues, addCategoryValidationSchema } from "../../schemas/AddCategory";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function CategoryUpload() {

  // get token
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  //Add Category
  const addCategoryFormik = useFormik({
    initialValues: addCategoryInitialValues,
    validationSchema: addCategoryValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (!values.images) {
        handleAlert("Please Choose Category Images", "erorr")
        return
      }
      const formData = new FormData();

      new Array(values.images.length).fill(0).map((_, i) => {
        formData.append(`image`, values.images[i]);
        return 0;
      });

      formData.append('name', values.name);

      // console.log("done one");
      await axios
        .post(`${process.env.REACT_APP_SERVER_URL}/category`, formData, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          setIsLoading(false);
          navigate(`${process.env.REACT_APP_CATEGORIES_LIST_ROUTE}`);
          handleAlert(res.data.message, "success");
          resetForm();
        })
        .catch((err) => {
          setIsLoading(true);
          handleAlert(err.response.data.message, "error");
        });
    },
  });

  return (
    <PageLayout>
      <form onSubmit={addCategoryFormik.handleSubmit}>
        <Row>
          <Col xl={12}>
            <CardLayout>
              <Breadcrumb title={"Category Upload"}>
                <li className="mc-breadcrumb-item">
                  <Anchor className="mc-breadcrumb-link" href={"/"}>
                    Home
                  </Anchor>
                </li>
                <li className="mc-breadcrumb-item">
                  Category
                </li>
                <li className="mc-breadcrumb-item">
                  Upload
                </li>
              </Breadcrumb>
            </CardLayout>
          </Col>
          <Col xl={12}>
            <CardLayout>
              <CardHeader title="Category information" />
              <Row>
                <Col xl={12}>
                  <MuiField
                    name="name"
                    label="Category Name"
                    type="text"
                    icon={<CategoryRounded />}
                    formik={addCategoryFormik}
                  />
                </Col>
                {/* <Col xl={6}>
                  <MuiField
                    name="stock"
                    label="Number of Stock"
                    icon={<TitleRounded />}
                    type="number"
                    formik={addCategoryFormik}
                  />
                </Col>
                <Col xl={6}>
                  <MuiField
                    name="year"
                    label="Number of Sold"
                    icon={<TitleRounded />}
                    type="number"
                    formik={addCategoryFormik}
                  />
                </Col> */}

              </Row>
            </CardLayout>
          </Col>
          <Col xl={6}>
            <CardLayout>
              <CardHeader
                title="Category Images"
              />
              <Box className="mc-product-upload-media">
                <Box className="mc-product-upload-file">
                  <Input
                    type="file"
                    id="images"
                    multiple
                    onChange={(event) => {
                      addCategoryFormik.setFieldValue('images', event.currentTarget.files);
                    }}
                  />
                  <Label htmlFor="images">
                    <Icon type="collections" />
                    <Text className={`${addCategoryFormik.values.images && "whatsapp"}`}>{addCategoryFormik.values.images ? "Upload Successfully!!" : "Upload Images"}</Text>
                  </Label>
                </Box>
              </Box>
            </CardLayout>
          </Col>
          <Col xl={6}>
            {addCategoryFormik.values.images && (
              <CardLayout>
                <CardHeader
                  title="Chosen Images"
                />
                <Box className={`grid jcs aifs g10 images_box_2`}>
                  {
                    new Array(addCategoryFormik.values.images.length).fill(0).map((_, i) => {
                      return (
                        <Image key={i} src={URL.createObjectURL(addCategoryFormik.values.images[i])} alt={"images"} className={`image`} />
                      )
                    })
                  }
                </Box>
              </CardLayout>
            )}
          </Col>
          <Col xl={12}>
            <Button
              className={`mc-auth-btn ${data?.button.fieldSize}`}
              type={"submit"}
            >
              {isLoading ? <Spinner animation="grow" /> : "Publish Category"}

            </Button>
          </Col>
        </Row>
      </form>
    </PageLayout>
  );
}
