import * as yup from "yup"

export const addBrandValidationSchema = yup.object({
    name: yup.string('Enter your brand Name').required('Brand Name is required'),
    // discountPercentage: yup.string('Enter discount Percentage').required('Discount Percentage is required'),
    // expirationDate: yup.string('Enter Expiration Date').required('Expiration Date is required').test('expirationDate', 'Expiration Date must be in the future', function (value) {
    //     const expirationDate = new Date(value);
    //     return !isNaN(expirationDate.getTime()) && expirationDate > new Date();
    // }),
    // usageLimit: yup.string('Enter Usage Limit').required('Usage Limit is required'),
})

export const addBrandInitialValues = {
    name: "",
    images: null,
}