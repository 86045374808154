import React from "react";
import { Breadcrumb } from "../../components";
import { CardLayout } from "../../components/cards";
import { Anchor, Item } from "../../components/elements";
import data from "../../data/master/pages.json";
import PageLayout from "../../layouts/PageLayout";

export default function Pages() {
  return (
    <PageLayout>
      <CardLayout className="mb-4">
        <Breadcrumb title={data?.pageTitle}>
          {data?.breadcrumb.map((item, index) => (
            <Item key={index} className="mc-breadcrumb-item">
              {item.path ? (
                <Anchor className="mc-breadcrumb-link" href={item.path}>
                  {item.text}
                </Anchor>
              ) : (
                item.text
              )}
            </Item>
          ))}
        </Breadcrumb>
      </CardLayout>
    </PageLayout>
  );
}
