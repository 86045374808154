import * as yup from "yup"

export const resetPasswordValidationSchema = yup.object({
  password: yup.string('Enter your password')
  .min(8, 'Password should be of minimum 8 characters length')
  .required('Password is required'),
  confirmPassword:yup.string('Confirm Password').oneOf([yup.ref('password'), null], 'Passwords must match')
  .required('Confirm Password is required'),
})

export const resetPasswordInitialValues={
  password:"",
  confirmPassword:""
}