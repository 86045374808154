import * as yup from "yup"

export const editBlogValidationSchema = yup.object({
  title:yup.string('Enter your title')
  .required('Title is required'),
  content: yup.string('Enter your content')
  .required('Content is required'),
  tags: yup.string('Enter tags'),
  urls: yup.string('Enter URL')
})

export const editBlogInitialValues={
  title:"",
  content:"",
  tags:"",
  urls:"",
  images:[]
}